import { Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import axios from "../../../services/axios";
import { COUNTRIES, TOAST_LVL } from "../../../constants/constants";
import ConfirmationContext from "../../../contexts/ConfirmationContext";

const BillingInformations = ({ account }) => {
	const { user, fetch_account } = useContext(AuthContext);
	const { setToast } = useContext(ConfirmationContext);
	const [editAddress, seteditAddress] = useState(false);

	const [settingsData, setSettingsData] = useState(
		account.payment
			? account.payment
			: {
					business_name: "",
					business_number: "",
					billing_address_l1: "",
					billing_address_l2: "",
					billing_address_postal_code: "",
					billing_address_city: "",
					billing_address_country: "",
			  }
	);

	useEffect(() => {
		setSettingsData(
			account.payment
				? account.payment
				: {
						business_name: "",
						business_number: "",
						billing_address_l1: "",
						billing_address_l2: "",
						billing_address_postal_code: "",
						billing_address_city: "",
						billing_address_country: "",
				  }
		);
	}, [account]);

	const save_billing_address = async () => {
		const resp = await axios
			.put(`/api/accounts/${account.id}/billing-details`, { ...settingsData })
			.then((e) => e.data)
			.catch((e) => null);
		if (resp) {
			setToast.current(
				TOAST_LVL.SUCCESS,
				"Update successfully",
				"The billing informaitons have been updated"
			);
			const payment = await fetch_account(account.id);
			if (payment) setSettingsData(payment.payment);
		}
	};

	const reset_billing_address = () => {
		setSettingsData(
			account.payment
				? account.payment
				: {
						business_name: "",
						business_number: "",
						billing_address_l1: "",
						billing_address_l2: "",
						billing_address_postal_code: "",
						billing_address_city: "",
						billing_address_country: "",
				  }
		);
	};

	const reset_only_address = () => {
		if (!account.payment) {
			setSettingsData({
				...settingsData,
				billing_address_l1: "",
				billing_address_l2: "",
				billing_address_postal_code: "",
				billing_address_city: "",
				billing_address_country: "",
			});
		} else {
			setSettingsData({
				...settingsData,
				billing_address_l1: account.payment.billing_address_l1,
				billing_address_l2: account.payment.billing_address_l2,
				billing_address_postal_code: account.payment.billing_address_postal_code,
				billing_address_city: account.payment.billing_address_city,
				billing_address_country: account.payment.billing_address_country,
			});
		}
		seteditAddress(false);
	};

	return (
		<>
			<div className="setting-block need-validation">
				<div className="block-container">
					<div className="setting-block-description">
						<h2>Billing informations</h2>
						<p>
							All the informations of this section will be used on invoices.
							<br />
							Please make sure everything is correct.
						</p>
					</div>
					<div className="setting-block-content column">
						<TextField
							size="small"
							sx={{ minWidth: "240px" }}
							id="outlined-basic"
							value={settingsData.business_name}
							onChange={(e) =>
								setSettingsData({ ...settingsData, business_name: e.target.value })
							}
							label={"Business name"}
							variant="outlined"
						/>
						<TextField
							size="small"
							sx={{ minWidth: "240px" }}
							id="outlined-basic"
							value={settingsData.business_number}
							onChange={(e) =>
								setSettingsData({
									...settingsData,
									business_number: e.target.value,
								})
							}
							label={"Business number"}
							variant="outlined"
						/>
						<TextField
							onClick={() => seteditAddress(true)}
							className="button-input"
							size="small"
							sx={{ minWidth: "240px" }}
							id="outlined-basic"
							value={
								settingsData.billing_address_l1
									? `${settingsData.billing_address_l1}, ${settingsData.billing_address_postal_code} ${settingsData.billing_address_city}, ${settingsData.billing_address_country}`
									: ""
							}
							label={"Billing address"}
							variant="outlined"
						/>
					</div>
				</div>
				<div className="settings-block-action">
					<Button onClick={reset_billing_address} variant="outlined" color="warning">
						Cancel
					</Button>
					<Button
						disabled={JSON.stringify(settingsData) == JSON.stringify(account.payment)}
						onClick={save_billing_address}
						variant="contained"
					>
						Save
					</Button>
				</div>
			</div>
			<Modal
				open={editAddress}
				onClose={reset_only_address}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
			>
				<div className="address-modal-view">
					<h2>Setup the billing address</h2>
					<FormControl required={true} size="small" fullWidth>
						<InputLabel id="demo-simple-select-label">Country</InputLabel>
						<Select
							required={true}
							fullWidth
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							size="small"
							value={settingsData.billing_address_country}
							label="Country"
							onChange={(e) =>
								setSettingsData({
									...settingsData,
									billing_address_country: e.target.value,
								})
							}
						>
							{COUNTRIES.map((elem, id) => (
								<MenuItem key={id} value={elem["Code"]}>
									{elem["Name"]}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<TextField
						sx={{ marginTop: "10px" }}
						size="small"
						fullWidth
						id="outlined-basic"
						value={settingsData.billing_address_l1}
						onChange={(e) =>
							setSettingsData({ ...settingsData, billing_address_l1: e.target.value })
						}
						required={true}
						label={"Address line 1"}
						variant="outlined"
					/>
					<TextField
						sx={{ marginTop: "10px" }}
						size="small"
						fullWidth
						id="outlined-basic"
						value={settingsData.billing_address_l2}
						onChange={(e) =>
							setSettingsData({ ...settingsData, billing_address_l2: e.target.value })
						}
						label={"Address line 2"}
						variant="outlined"
					/>
					<div className="row">
						<TextField
							size="small"
							fullWidth
							id="outlined-basic"
							value={settingsData.billing_address_city}
							onChange={(e) =>
								setSettingsData({
									...settingsData,
									billing_address_city: e.target.value,
								})
							}
							label={"City"}
							required={true}
							variant="outlined"
						/>
						<TextField
							size="small"
							fullWidth
							id="outlined-basic"
							value={settingsData.billing_address_postal_code}
							onChange={(e) =>
								setSettingsData({
									...settingsData,
									billing_address_postal_code: e.target.value,
								})
							}
							required={true}
							label={"Postal code"}
							variant="outlined"
						/>
					</div>
					<div className="validate">
						<Button onClick={reset_only_address} variant="outlined" color="warning">
							Cancel
						</Button>
						<Button onClick={() => seteditAddress(false)} variant="contained">
							Valider
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default BillingInformations;
