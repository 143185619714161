import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from './screens/log/login/Login'
import Register from './screens/log/register/Register'

const Log = () => {
  return (
    <Routes>
      <Route path='/login' element={<Login/>} />
      <Route path='/register' element={<Register/>} />
      <Route path='/verify-email' element={<Register/>} />
      <Route path='/' element={<Login/>} />
      <Route path="*" element={<Navigate to="/login"/>}/>
	  </Routes>
  )
}

export default Log