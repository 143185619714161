import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import DefaultView from "../../../components/account_settings/default_view/DefaultView";
import AuthContext from "../../../contexts/AuthContext";
import { Button, MenuItem, Select } from "@mui/material";
import ConfirmationContext from "../../../contexts/ConfirmationContext";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import axios from "../../../services/axios";
import { TOAST_LVL } from "../../../constants/constants";
import NewMember from "../../../components/account_settings/members/new_member/NewMember";

const AccountMembers = () => {
	const { user } = useContext(AuthContext);
	const { open_confirm, setToast } = useContext(ConfirmationContext);
	const [newUser, setNewUser] = useState(false);
	const [members, setMembers] = useState([]);
	const account = useRef(null);

	const load_settings = async () => {
		const response = await axios
			.get(`/api/accounts/${user.account.id}`)
			.then((e) => e.data)
			.catch((e) => null);
		if (response) {
			account.current = response;
			setMembers(JSON.parse(JSON.stringify(response.users)));
		}
	};

	useEffect(() => {
		load_settings();
	}, []);

	const delete_user = async (delete_id, name) => {
		const ret = await axios
			.delete(`/api/accounts/${user.account.id}/users/${delete_id}`)
			.then((e) => e.data)
			.catch((e) => null);
		setToast.current(
			TOAST_LVL.SUCCESS,
			"Member deleted",
			`The access rights on ${user.account.name} to ${name} have been revoked`
		);
		load_settings();
	};

	const save = async () => {
		const to_update = [];
		for (let index = 0; index < members.length; index++) {
			const member = members[index];
			const equivalent = user.account.users.filter((e) => e.id == member.id);
			if (equivalent.length && equivalent[0].role != member.role) {
				to_update.push(member);
			}
		}
		for (let index = 0; index < to_update.length; index++) {
			const element = to_update[index];
			const ret = await axios
				.put(`/api/accounts/${user.account.id}/users/${element.id}`, {
					role: element.role,
				})
				.then((e) => e.data)
				.catch((e) => {
					return null;
				});
		}
		setToast.current(
			TOAST_LVL.SUCCESS,
			"Access updated",
			`The access rights on ${user.account.name} have been updated`
		);
		load_settings();
	};

	const reset = () => {
		setMembers(JSON.parse(JSON.stringify(account.current.users)));
	};

	const valid = () => {
		return members.length && JSON.stringify(members) !== JSON.stringify(account.current?.users);
	};

	const handleChange = (event, id) => {
		const new_value = members.map((elem) => {
			if (elem.id == id) {
				elem.role = event.target.value;
			}
			return elem;
		});
		setMembers(new_value);
	};

	return (
		<DefaultView save={save} reset={reset} is_valid={valid}>
			<div className="settings-content">
				<div className="settings-center">
					<div className="setting-block-title">
						<h1>Members: {user.account.name} account</h1>
						<p>
							From here as an administrator you can add member to your account and
							manage theirs rights on the account.
						</p>
					</div>
					<div className="setting-block">
						<div className="block-container full-width">
							<div className="setting-block-description">
								<h2>Member list</h2>
								<p>The user of this list have access to this account.</p>
							</div>
							<div className="setting-block-content" id="account-members">
								<div className="table-container-users">
									<table>
										<thead>
											<tr>
												<th align="start">User</th>
												<th align="start">Role</th>
												<th className="delete">
													<Button
														onClick={() => setNewUser(true)}
														variant="outlined"
													>
														New member
													</Button>
												</th>
											</tr>
										</thead>
										<tbody>
											{members.map((elem, id) => (
												<tr key={id}>
													<td>
														<div className="user-name-container">
															<p>
																{elem.first_name} {elem.last_name}{" "}
																{elem.id == user.id ? "(you)" : ""}
															</p>
															{elem.id == user.account.owner ? (
																<span className="is_admin">
																	Administrator
																</span>
															) : null}
														</div>
													</td>
													<td>
														{elem.id == user.account.owner ? null : (
															<Select
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={elem.role}
																size="small"
																onChange={(e) =>
																	handleChange(e, elem.id)
																}
															>
																<MenuItem value={"default"}>
																	Visitor
																</MenuItem>
																<MenuItem value={"developer"}>
																	Developer
																</MenuItem>
																<MenuItem value={"accountant"}>
																	Accountant
																</MenuItem>
															</Select>
														)}
													</td>
													{elem.id == user.account.owner ? (
														<td></td>
													) : (
														<td
															onClick={() =>
																open_confirm(
																	`Delete api key "${elem.name}"`,
																	"This is a destructive action. All the rights to this user will be revoked.",
																	`Revoke`,
																	() =>
																		delete_user(
																			elem.id,
																			`${elem.last_name} ${elem.first_name}`
																		)
																)
															}
															className="delete"
															align="end"
														>
															<DeleteOutlineIcon />
														</td>
													)}
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<NewMember
				add_member={load_settings}
				close={() => setNewUser(false)}
				active={newUser}
			/>
		</DefaultView>
	);
};

export default AccountMembers;
