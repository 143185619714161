import React, { useEffect, useState } from "react";
import ChoosePassword from "../../../components/log/choose_password/ChoosePassword";
import ConfirmEmail from "../../../components/log/confirm_email/ConfirmEmail";
import CreateFirstAccount from "../../../components/log/create_first_account/CreateFirstAccount";
import RegisterDetails from "../../../components/log/register_details/RegisterDetails";
import RegisterView from "../../../components/log/register_view/RegisterView";
import { getData, storeData } from "../../../functions/store_data";
import Pending from "./Pending";

const Register = () => {
	const [step, setStep] = useState(1);

	useEffect(() => {
		const data = getData();
		if (data.register_step) {
			setStep(data.register_step);
		}
	}, []);

	const next_step = (step) => {
		storeData("register_step", step);
		setStep(step);
	};

	return <Pending />;

	return (
		<RegisterView step={step}>
			{step == 1 ? (
				<RegisterDetails next_step={() => next_step(step + 1)} />
			) : step == 2 ? (
				<ConfirmEmail next_step={() => next_step(step + 1)} />
			) : step == 3 ? (
				<ChoosePassword next_step={() => next_step(step + 1)} />
			) : (
				<CreateFirstAccount next_step={() => next_step(step + 1)} />
			)}
		</RegisterView>
	);
};

export default Register;
