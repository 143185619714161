import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import DownTimeGraph from "../../../components/devices/DownTimeGraph/DownTimeGraph";
import Loading from "../../../components/main/loading/Loading";
import AuthContext from "../../../contexts/AuthContext";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import Table from "../../../components/main/table/Table";
import "./style.scss";
import { set_doc_title } from "../../../functions/main";
import DeviceDataMonitore from "../../../components/devices/DeviceDataMonitore/DeviceDataMonitore";

const is_same_day = (date1, date2) => {
	return date1.toDateString() == date2.toDateString();
};

const generate_times = (up_time, days, count_per_days) => {
	const data = [];
	const up_times = up_time.map((elem) => {
		return { ...elem, start: new Date(elem.created_at), end: null };
	});
	let date = new Date();
	date.setDate(date.getDate() - 3);
	for (let per_day = 0; per_day < count_per_days * days; per_day++) {
		date.setTime(date.getTime() + parseInt(86400 / count_per_days) * 1000);
		if (date.getTime() > new Date().getTime()) break;
		data.push({
			start: new Date(date.getTime()),
			end: null,
			down_time: 0,
		});
	}
	for (let index = 0; index < data.length; index++) {
		if (index != data.length - 1) {
			data[index].end = data[index + 1].start;
		} else {
			data[index].end = new Date();
		}
	}
	for (let index = 0; index < up_times.length; index++) {
		if (index != up_times.length - 1) {
			up_times[index].end = up_times[index + 1].start;
		} else {
			up_times[index].end = new Date();
		}
	}
	let current_index = 0;
	for (let index = 0; index < up_times.length; index++) {
		const element = up_times[index];
		const elem_time_start = element.start.getTime();
		const elem_time_end = element.end.getTime();
		if (!element.is_up) {
			while (current_index < data.length) {
				const current_time_end = data[current_index].end.getTime();
				if (current_time_end < elem_time_start) {
					if (index == 0) {
						data[current_index].down_time = -1;
					}
					current_index++;
				} else {
					const current_time_start = data[current_index].start.getTime();

					let start_elaps = elem_time_start - current_time_start;
					let end_elapse = elem_time_end - current_time_end;
					let time_elipsed =
						(elem_time_end -
							current_time_start -
							(end_elapse > 0 ? end_elapse : 0) -
							(start_elaps > 0 ? start_elaps : 0)) /
						1000;
					if (time_elipsed > (24 / count_per_days) * 3600)
						time_elipsed = (24 / count_per_days) * 3600;
					data[current_index].down_time += time_elipsed;

					if (current_time_end > elem_time_end) break;
					else {
						current_index++;
					}
				}
			}
		}
	}
	return data;
};

let count_per_day = window.innerWidth / 45 < 8 ? 8 : window.innerWidth / 45;
let timout = null;

const DeviceDetails = ({ devices, fetchSpecificDevices }) => {
	const { device_id } = useParams();
	const { user } = useContext(AuthContext);
	const [device, setDevice] = useState(null);
	const graph = useRef(null);

	useEffect(() => {
		const handleResize = () => {
			if (timout) {
				clearTimeout(timout);
				timout = null;
			}
			timout = setTimeout(() => {
				count_per_day = window.innerWidth / 45 < 8 ? 8 : window.innerWidth / 45;
				const current_dev = devices.filter((elem) => elem.id == device_id);
				if (current_dev.length && current_dev[0] != device && current_dev[0].up_times) {
					setDevice({
						...current_dev[0],
						down_times: generate_times(
							current_dev[0].up_times,
							window.innerWidth < 400 ? 1 : 3,
							count_per_day
						),
					});
				}
			}, 200);
		};
		set_doc_title("DeviceDetails", user.account.name);
		if (device_id) {
			fetchSpecificDevices({ account_id: user.account.id, device_id: device_id });
		}
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		const current_dev = devices.filter((elem) => elem.id == device_id);
		if (current_dev.length && current_dev[0] != device && current_dev[0].up_times) {
			setDevice({
				...current_dev[0],
				down_times: generate_times(
					current_dev[0].up_times,
					window.innerWidth < 400 ? 1 : 3,
					count_per_day
				),
			});
		}
	}, [devices]);

	if (!device?.up_times) return <Loading />;

	return (
		<div id="device-details">
			<DeviceDataMonitore device={device} />
			<div ref={graph}>
				<div className="graph-div">
					<div className="graph-header">
						<h3>Device uptime</h3>
					</div>
					<DownTimeGraph data={device.down_times} period={(24 / count_per_day) * 3600} />
				</div>
			</div>
			<div>
				<Table
					contained={true}
					data={device.logs}
					selectable={false}
					title={"Device logs"}
					used_columns={[
						{
							column: "level",
							str_column: "Level",
							var_type: "card",
							card_value: {
								0: { title: "Debug", color: "var(--pending)" },
								1: { title: "Info", color: "var(--pending)" },
								2: { title: "Warning", color: "var(--warning)" },
								3: { title: "Error", color: "var(--error)" },
							},
						},
						{
							column: "created_at",
							str_column: "Created at",
							var_type: "date-full",
						},
						{
							column: "title",
							str_column: "Title",
							var_type: "str",
						},
						{
							column: "content",
							str_column: "Content",
							var_type: "str",
						},
					]}
				/>
			</div>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetails);
