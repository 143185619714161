import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "./screens/404/404";
import AccountNavigation from "./screens/account_settings/account_navigation/AccountNavigation";
import Billing from "./screens/account_settings/billing/Billing";
import DevelopersSettings from "./screens/account_settings/developers_settings/DevelopersSettings";
import GeneralAccountSettings from "./screens/account_settings/general_settings/GeneralAccountSettings";
import NotificationsSettings from "./screens/account_settings/notifications_settings/NotificationsSettings";
import AllMessages from "./screens/all_messages/AllMessages";
import BlackList from "./screens/black_list/BlackList";
import Dashboard from "./screens/dashboard/Dashboard";
import DeviceDetails from "./screens/devices/details/DeviceDetails";
import Devices from "./screens/devices/devices/Devices";
import Register from "./screens/log/register/Register";
import PricingPlan from "./screens/pricing_plan/PricingPlan";
import ScheduledMessages from "./screens/scheduled_messages/ScheduledMessages";
import Billings from "./screens/settings/billing/Billings";
import GeneralSettings from "./screens/settings/general_settings/GeneralSettings";
import Profile from "./screens/settings/profile/Profile";
import SettingsNavigation from "./screens/settings/settings_navigation/SettingsNavigation";
import Test from "./test";
import AccountMembers from "./screens/account_settings/members/AccountMembers";
import TakeOver from "./screens/admin/TakeOver";

const Main = ({ user }) => {
	return (
		<Routes>
			<Route path="/" element={<Dashboard />} />
			<Route path="/messages" element={<AllMessages />} />
			<Route path="/tests" element={<Test />} />
			<Route path="/black-list-numbers" element={<BlackList />} />
			<Route path="/scheduled-messages" element={<ScheduledMessages />} />
			<Route path="/devices" element={<Devices />} />

			<Route path="/account-settings" element={<GeneralAccountSettings />} />
			<Route path="/account-settings/notifications" element={<NotificationsSettings />} />
			<Route path="/account-settings/developers" element={<DevelopersSettings />} />
			<Route path="/account-settings/billing" element={<Billing />} />
			<Route path="/account-settings/billing/:account_id" element={<Billing />} />
			<Route path="/account-settings/navigation" element={<AccountNavigation />} />
			<Route path="/account-settings/members" element={<AccountMembers />} />

			<Route path="/pricing-plan" element={<PricingPlan />} />
			<Route path="/next-pricing-plan" element={<PricingPlan />} />

			<Route path="/settings" element={<GeneralSettings />} />
			<Route path="/settings/billing" element={<Billings />} />
			<Route path="/settings/profile" element={<Profile />} />
			<Route path="/settings/navigation" element={<SettingsNavigation />} />

			<Route path="/devices/:device_id" element={<DeviceDetails />} />

			{user.is_admin ? (
				<>
					<Route path="/admin/users/:user_id" element={<TakeOver />} />
					<Route path="/admin/users/:user_id/:session_time" element={<TakeOver />} />
				</>
			) : null}

			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};

export default Main;
