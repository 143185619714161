import { Button, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ConfirmationContext from "../../../contexts/ConfirmationContext";
import twilio from "../../../assets/implementations/twilio.png";
import vonage from "../../../assets/implementations/vontage.png";
import "./style.scss";
import axios from "../../../services/axios";
import { TOAST_LVL } from "../../../constants/constants";

const is_today = (date) => {
	let today = new Date();

	let desiredDate = new Date(date);

	return (
		desiredDate.getFullYear() === today.getFullYear() &&
		desiredDate.getMonth() === today.getMonth() &&
		desiredDate.getDate() === today.getDate()
	);
};

const BackupService = ({ account, reload }) => {
	const [implementation, setImplementation] = useState(account.fallback);
	const { open_confirm, setToast } = useContext(ConfirmationContext);
	const [dailyLimit, setDailyLimit] = useState(
		implementation ? (implementation.daily_limit == -1 ? false : true) : false
	);

	useEffect(() => {
		setImplementation(account.fallback);
	}, [account]);

	const drop = async () => {
		await axios.delete(`/api/fallback/${account.id}/${account.fallback.id}`);
		reload();
	};

	const delete_implementation = () => {
		open_confirm("Drop backup", "By dropping backup some sms might be blocked.", "", drop);
	};

	const save_implementation = async () => {
		if (!implementation.id) {
			const response = await axios
				.post(`/api/fallback/${account.id}`, {
					platform: implementation.platform,
					account_identifier: implementation.account_identifier,
					account_key: implementation.account_key,
					additional_informations: implementation.additional_informations,
					daily_limit: dailyLimit ? parseFloat(implementation.daily_limit) : -1,
				})
				.then((e) => e)
				.catch((e) => e);
			if (response.status == 200) {
				reload();
			} else {
				if (response.response.data.message == "Invalid vonage creditentials")
					setToast.current(
						TOAST_LVL.ERROR,
						"Invalid creditentials",
						"Your vonage creditentials are not valid"
					);
				else if (response.response.data.message == "Invalid twilio creditentials")
					setToast.current(
						TOAST_LVL.ERROR,
						"Invalid creditentials",
						"Your twilio creditentials are not valid"
					);
			}
		} else {
			const response = await axios
				.put(`/api/fallback/${account.id}/${account.fallback.id}`, {
					platform: implementation.platform,
					account_identifier: implementation.account_identifier,
					account_key: implementation.account_key,
					additional_informations: implementation.additional_informations,
					daily_limit: dailyLimit ? parseFloat(implementation.daily_limit) : -1,
				})
				.then((e) => e)
				.catch((e) => e);
			if (response.status == 200) {
				reload();
			} else {
				console.log(response);
				if (response.response.data.message == "Invalid vonage creditentials")
					setToast.current(
						TOAST_LVL.ERROR,
						"Invalid creditentials",
						"Your vonage creditentials are not valid"
					);
				else if (response.response.data.message == "Invalid twilio creditentials")
					setToast.current(
						TOAST_LVL.ERROR,
						"Invalid creditentials",
						"Your twilio creditentials are not valid"
					);
			}
		}
	};

	if (!implementation) {
		return (
			<div className="setting-block-content empty" id="implementation-divider">
				<span>
					For now you don't have any backup service. To configure one click on your sms
					service bellow. <a href="https://docs.smsdispatcher.app/">Learn more</a>
				</span>
				<div className="logo-container">
					<div
						onClick={() =>
							setImplementation({
								platform: "twilio",
								account_identifier: "",
								account_key: "",
								additional_informations: "",
							})
						}
						className="implementation-logo"
					>
						<img alt="twilio-logo" src={twilio} />
					</div>
					<div
						onClick={() =>
							setImplementation({
								platform: "vonage",
								account_identifier: "",
								account_key: "",
								additional_informations: "-",
							})
						}
						className="implementation-logo light"
					>
						<img alt="vonage-logo" src={vonage} />
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<div className="setting-block-content" id="implementation-divider">
				<span className="header-information">
					If you need more information about how to implement backup using{" "}
					{implementation.platform} you can check our{" "}
					<a href="https://docs.smsdispatcher.app/">detailed guide</a>
				</span>
				{implementation.platform == "twilio" ? (
					<div className={"implementation-logo"}>
						<img alt="twilio-logo" src={twilio} />
					</div>
				) : (
					<div className={"implementation-logo light"}>
						<img alt="vonage-logo" src={vonage} />
					</div>
				)}
				<form className="implementation-form">
					<TextField
						size="small"
						label={implementation.platform == "twilio" ? "Account SID" : "API key"}
						onChange={(e) =>
							setImplementation({
								...implementation,
								account_identifier: e.target.value,
							})
						}
						value={implementation.account_identifier}
					/>
					<TextField
						size="small"
						label={implementation.platform == "twilio" ? "Auth token" : "API secret"}
						onChange={(e) =>
							setImplementation({ ...implementation, account_key: e.target.value })
						}
						value={implementation.account_key}
					/>
					{implementation.platform == "twilio" ? (
						<TextField
							size="small"
							label="Your twilio phone number"
							onChange={(e) =>
								setImplementation({
									...implementation,
									additional_informations: e.target.value,
								})
							}
							value={implementation.additional_informations}
						/>
					) : null}
				</form>
			</div>
			{!account.fallback ? null : (
				<div id="fallback-limit-set-div" className="setting-block-description large">
					<h3>Add a daily limit for this fallback</h3>
					<p>
						This daily limit is the maximal amount that will be use from your backup
						account. If the limit has been reach the backup will not be used until the
						next day.
					</p>
					<div className="daily-limit-current">
						{account.fallback.daily_limit == -1 || !dailyLimit ? null : is_today(
								account.fallback.daily_limit_time
						  ) ? (
							<p>
								Actual value:{" "}
								<span
									className={`${
										account.fallback.daily_limit_value >
										account.fallback.daily_limit
											? "upper"
											: ""
									}`}
								>
									{account.fallback.daily_limit_value}€ /{" "}
									{account.fallback.daily_limit}€
								</span>
							</p>
						) : (
							<p>Actual value: 0€ / {account.fallback.daily_limit}€</p>
						)}
					</div>
					<div className="limit-setup">
						{!dailyLimit ? (
							<Button onClick={() => setDailyLimit(true)} variant="outlined">
								Setup a daily limit
							</Button>
						) : (
							<div className="daily-limit-inputs">
								<TextField
									fullWidth
									type="number"
									size="small"
									label={"Daily limit (€)"}
									value={
										implementation.daily_limit == -1
											? 0
											: implementation.daily_limit
									}
									onChange={(e) =>
										setImplementation({
											...implementation,
											daily_limit: e.target.value,
										})
									}
								/>
								<Button
									fullWidth
									color="error"
									variant="outlined"
									onClick={() => {
										setDailyLimit(false);
										setImplementation({ ...implementation, daily_limit: -1 });
									}}
								>
									Remove daily limit
								</Button>
							</div>
						)}
					</div>
				</div>
			)}
			{!account.fallback ? null : (
				<div className="settings-block-action">
					<Button onClick={delete_implementation} variant="outlined" color="error">
						Drop
					</Button>
					<Button
						disabled={
							JSON.stringify(implementation) == JSON.stringify(account.fallback) ||
							implementation.account_identifier == "" ||
							implementation.account_key == "" ||
							implementation.additional_informations == ""
						}
						onClick={save_implementation}
						variant="contained"
					>
						Save
					</Button>
				</div>
			)}
			{account.fallback || !implementation ? null : (
				<div className="settings-block-action">
					<Button onClick={() => setImplementation(null)} variant="text" color="error">
						calcel
					</Button>
					<Button
						disabled={
							implementation.account_identifier == "" ||
							implementation.account_key == "" ||
							implementation.additional_informations == ""
						}
						onClick={save_implementation}
						variant="contained"
					>
						Create
					</Button>
				</div>
			)}
		</>
	);
};

export default BackupService;
