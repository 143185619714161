import { TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import CreateBox from "../../components/main/create_box/CreateBox";
import Loading from "../../components/main/loading/Loading";
import Table from "../../components/main/table/Table";
import TableHeader from "../../components/main/table_header/TableHeader";
import { TOAST_LVL } from "../../constants/constants";
import AuthContext from "../../contexts/AuthContext";
import ConfirmationContext from "../../contexts/ConfirmationContext";
import { set_doc_title } from "../../functions/main";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";

const BlackList = ({ black_list, fetchBlackListed, createBlackListed, deleteBlackListed }) => {
	const { user } = useContext(AuthContext);
	const { setToast } = useContext(ConfirmationContext);
	const [newOnList, setNewOnList] = useState(false);
	const new_blacklist_phone = useRef("");

	useEffect(() => {
		set_doc_title("BlackList", user.account.name);
		fetchBlackListed(user.account.id);
	}, []);

	const add_phone = () => {
		if (new_blacklist_phone.current != "") {
			createBlackListed({
				account_id: user.account.id,
				phone_number: new_blacklist_phone.current,
				reason: "0",
			});
			new_blacklist_phone.current = "";
		}
		setNewOnList(false);
	};

	const delete_bulk = async (ids) => {
		ids.forEach(async (id) => {
			await deleteBlackListed({ message_id: id });
		});
		setToast.current(
			TOAST_LVL.SUCCESS,
			"Deletion completed",
			`${ids.length} phone number${
				ids.length > 1 ? "s have" : " has"
			} been successfully deleted from blacklist`
		);
	};

	if (!black_list) {
		return <Loading />;
	}

	return (
		<div className="table-view">
			<CreateBox
				title={"Add number"}
				visible={newOnList}
				cancel={() => setNewOnList(false)}
				submit={() => add_phone()}
				submit_text="Create"
				submitable={true}
			>
				<div className="create-box-body">
					<TextField
						size="small"
						type="tel"
						defaultValue={new_blacklist_phone.current}
						onChange={(e) => (new_blacklist_phone.current = e.target.value)}
						fullWidth
						className="value-input"
						id="outlined-basic"
						label="Phone number"
						variant="outlined"
					/>
				</div>
			</CreateBox>
			<Table
				new_item={() => setNewOnList(true)}
				data={black_list}
				selectable={true}
				title={"Blacked list phone numbers"}
				new_text="Add on list"
				actions={{
					delete: {
						label: "Delete selection",
						action: delete_bulk,
					},
					more: [
						{
							label: "Download in xls format",
							action: () => console.log("download"),
						},
					],
				}}
				used_columns={[
					{
						column: "id",
						str_column: "id",
						var_type: "str",
					},
					{
						column: "phone_number",
						str_column: "phone number",
						var_type: "str",
					},
					{
						column: "reason",
						str_column: "reason",
						var_type: "card",
						card_value: {
							0: { title: "Add from dashboard", color: "var(--chart-color1)" },
							1: { title: "User send stop", color: "var(--chart-color2)" },
						},
					},
				]}
			/>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(BlackList);
