import React, { useContext, useState } from "react";
import "./login.scss";
import AuthContext from "../../../contexts/AuthContext";
import logo from "../../../assets/logo.png";
import main_screen from "../../../assets/main_screen.png";
import { Link, redirect, useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ConfirmationContext from "../../../contexts/ConfirmationContext";
import { TOAST_LVL } from "../../../constants/constants";

function LogInForm() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const { user, login } = useContext(AuthContext);
	const { setToast } = useContext(ConfirmationContext);
	const navigate = useNavigate();

	const handleSubmit = async () => {
		setLoading(true);
		const response = await login(email, password);
		if (!response) {
			setToast.current(TOAST_LVL.ERROR, "Login failed", "Bad email or password");
		}
		navigate("/");
		setLoading(false);
	};

	const validateEntry = () => {
		if (password == "" || email == "") return false;
		return true;
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter" && validateEntry()) {
			handleSubmit();
		}
	};

	return (
		<section className="login">
			<div className="login-container">
				<div className="logo-title">
					<img alt="smsdispatcher icon" src={logo} />
					<span>SmsDispatcher</span>
				</div>
				<div className="center_div">
					<h2>Sign in</h2>
					<form className="form" onSubmit={() => console.log("first")}>
						<TextField
							size="small"
							label="Email"
							type={"email"}
							autoComplete={"email"}
							fullWidth
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							variant="outlined"
						/>
						<TextField
							onKeyDown={handleKeyDown}
							size="small"
							label="Password"
							type={"password"}
							autoComplete={"password"}
							fullWidth
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							variant="outlined"
						/>
					</form>
					<LoadingButton
						loading={loading}
						variant="contained"
						disabled={!validateEntry()}
						onClick={() => {
							handleSubmit();
						}}
						fullWidth
					>
						Sign in
					</LoadingButton>
					<span className="other-method-link">
						You don't have any account ? <Link to="/register">Sign up</Link>
					</span>
				</div>
			</div>
			<div className="login-presentation">
				<div className="login-title">
					<h1>Welcome back!</h1>
					<p>To access your account, please login with your personal informaton.</p>
				</div>
				<img alt="smsdispatcher main screen" src={main_screen} />
			</div>
		</section>
	);
}

function Login() {
	const { user } = useContext(AuthContext);
	if (user.token) {
		return redirect("/");
	} else {
		return <LogInForm />;
	}
}

export default Login;
