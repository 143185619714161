import { Button, TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import { baseURL, socketURL } from "../../constants/constants";
import AuthContext from "../../contexts/AuthContext";
import { mapDispatchToProps } from "../../store/dispatcher";
import "./style.scss";

function generateString(length) {
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = "";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return result;
}

let name = "";

let ping_interval = null;

const PairDevice = ({ active, close, createDevices }) => {
	const [step, setStep] = useState(0);
	const [randomCheck, setRandomCheck] = useState(0);
	const [selectedColor, setSelectedColor] = useState("");
	const [device, setDevice] = useState(null);
	const { user } = useContext(AuthContext);
	const ws = useRef(null);

	useEffect(() => {
		return () => {
			clearInterval(ping_interval);
		};
	}, []);

	const pair_device = () => {
		createDevices({ account_id: user.account.id, device_id: device.id, device: device }).then(
			(e) => {
				if (ws.current) {
					ws.current.send("pair|" + user.account.id);
					ws.current.close();
					ws.current = null;
				}
			}
		);
		close_page(true);
	};

	if (!active) {
		document.body.style.overflow = "unset";
		return;
	}
	document.body.style.overflow = "hidden";

	if (!ws.current) {
		ws.current = new WebSocket(`${socketURL}/ws?account_id=${user.account.id}`);
		ws.current.onopen = () => {
			console.log("open", user.account.id);
			ping_interval = setInterval(() => {
				if (ws.current) ws.current.send("ping");
			}, 1000);
		};
		ws.current.onclose = () => {
			console.log("close");
			clearInterval(ping_interval);
		};
		ws.current.onerror = (e) => {
			console.log(e);
			if (ws.current) ws.current.close();
		};
		ws.current.onmessage = (msg) => {
			if (
				msg.data == "init" ||
				msg.data == "ok" ||
				msg.data == "ping" ||
				msg.data.endsWith("up") ||
				msg.data.endsWith("down")
			) {
				return;
			}
			const message = JSON.parse(msg.data);
			console.log("Hello: ", message, message.type);
			if (message.type == "random_number" && step == 0) {
				setRandomCheck(message.value);
				setStep(1);
			} else if (message.type == "device_info") {
				setDevice(message.value);
			}
		};
	}

	const close_page = async (process_proprely_ended) => {
		name = "";
		if (!process_proprely_ended) {
			if (ws.current) ws.current.send("end-process");
			await new Promise((r) => setTimeout(r, 100));
		}
		setSelectedColor("");
		setStep(0);
		close();
	};

	const RenderBody = () => {
		if (step == 1) {
			return (
				<div className="pair-device-body">
					<p>
						<span>Step 2:</span> Verify that the number bellow is the same that the
						number on your device.
					</p>
					<span className="big-number">{randomCheck}</span>
					<p>
						<a href="/">I don't see any number</a>
					</p>
				</div>
			);
		}
		return (
			<div className="pair-device-body">
				<p>
					<span>Step 1:</span> Open SmsDispatcher app on your phone and select the{" "}
					{user.account.name} account.
				</p>
				<div className="pair-device-account-selector">
					<span>{user.account.name}</span>
				</div>
				<p>
					How to get the <a href="/">SmsDispatcher app</a>?
				</p>
			</div>
		);
	};

	return (
		<div className="pair-device">
			<div className="pair-device-center">
				<div className="pair-device-header">
					<h3>Pair device</h3>
					<span>Step {step + 1} on 3</span>
				</div>
				<RenderBody />
				<div className="pair-device-footer">
					<Button onClick={() => close_page(false)} variant="outlined">
						Cancel
					</Button>
					<Button
						disabled={(selectedColor == "" && step == 2) || step == 0}
						onClick={() => (step == 1 ? pair_device() : setStep(step + 1))}
						variant="contained"
					>
						{step == 0 ? "Wait for pairing" : step == 1 ? "Pair device" : "Pair device"}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default connect(null, mapDispatchToProps)(PairDevice);
