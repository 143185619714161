import { Button } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../../settings/default_view/style.scss";

const DefaultView = ({ children, visible, save, reset, is_valid, hide_navbar }) => {
	const location = useLocation();

	return (
		<div id="settings-base-view" className={`${hide_navbar ? "hide-nav" : ""}`}>
			{hide_navbar ? null : (
				<ul className={`account-navigation ${visible == "always" ? "full" : "aside"}`}>
					<li
						className={location.pathname === "/account-settings" ? "active hov" : "hov"}
					>
						<Link to="/account-settings">
							<div className="account-nav-elem">
								<i className="fas fa-cog"></i>
								<div>
									<span>General settings</span>
									<p>Account name, default phone prefix...</p>
								</div>
							</div>
						</Link>
					</li>
					<li
						className={
							location.pathname === "/account-settings/notifications"
								? "active hov"
								: "hov"
						}
					>
						<Link to="/account-settings/notifications">
							<div className="account-nav-elem">
								<i className="fas fa-bell"></i>
								<div>
									<span>Notifications</span>
									<p>Notifications rules, notifications channel...</p>
								</div>
							</div>
						</Link>
					</li>
					<li
						className={
							location.pathname === "/account-settings/members" ? "active hov" : "hov"
						}
					>
						<Link to="/account-settings/members">
							<div className="account-nav-elem">
								<i className="fas fa-users"></i>
								<div>
									<span>Members</span>
									<p>User access to this account, access rights...</p>
								</div>
							</div>
						</Link>
					</li>
					<li
						className={
							location.pathname === "/account-settings/billing" ? "active hov" : "hov"
						}
					>
						<Link to="/account-settings/billing">
							<div className="account-nav-elem">
								<i className="fas fa-money-bill-wave-alt"></i>
								<div>
									<span>Billing</span>
									<p>Billing details, current plan, invoices...</p>
								</div>
							</div>
						</Link>
					</li>
				</ul>
			)}
			{!save ? (
				<>{children}</>
			) : (
				<>
					{children}
					<div className="save-changes">
						<Button onClick={reset} variant="text">
							reset
						</Button>
						<Button onClick={save} disabled={!is_valid()} variant="contained">
							Save
						</Button>
					</div>
				</>
			)}
		</div>
	);
};

export default DefaultView;
