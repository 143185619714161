import React, { useRef } from "react";
import "./style.scss";

var opt_weekday = {
	weekday: "short",
	year: "numeric",
	month: "short",
	day: "numeric",
	hour: "numeric",
	minute: "numeric",
	second: "numeric",
};
const short_date = { month: "short", day: "numeric" };

const DownTimeGraph = ({ data, period }) => {
	const label_abs = useRef(null);

	for (let index = 0; index < data.length; index++) {
		const element = data[index];
		element["time_type"] =
			element.down_time == -1
				? "unset"
				: element.down_time <= 120
				? "ok"
				: element.down_time / period < 0.5
				? "warn"
				: "error";
	}

	const set_absolute_label = (target, elem) => {
		let parentPos = document.getElementById("down-time-graph").getBoundingClientRect();
		let childPos = target.getBoundingClientRect();
		if (
			childPos.left - parentPos.left <
			document.getElementById("down-time-graph").offsetWidth - label_abs.current.offsetWidth
		)
			label_abs.current.style.left = `${childPos.left - parentPos.left}px`;
		else
			label_abs.current.style.left = `${
				document.getElementById("down-time-graph").offsetWidth -
				label_abs.current.offsetWidth -
				20
			}px`;
		document.getElementById("absolute-label-from").innerHTML = elem.start.toLocaleDateString(
			`fr-FR`,
			opt_weekday
		);
		document.getElementById("absolute-label-to").innerHTML = elem.end.toLocaleDateString(
			`fr-FR`,
			opt_weekday
		);
		const up_v = (elem.down_time / period) * 100;
		document.getElementById("percentage-up").innerHTML =
			elem.time_type != "unset"
				? (100 - (up_v < 0 ? 0 : up_v > 100 ? 100 : up_v)).toFixed(2)
				: "--";
	};

	return (
		<div id="down-time-graph" className="down_time_graph">
			<div ref={label_abs} className="absolute-label">
				<p>
					<span className="form-to">From:</span> <span id="absolute-label-from"></span>
				</p>
				<p>
					<span className="form-to">To:</span> <span id="absolute-label-to"></span>
				</p>
				<p>
					Up time: <span id="percentage-up"></span>%
				</p>
			</div>
			<div
				onMouseLeave={() => label_abs.current.classList.remove("show")}
				onMouseEnter={() => label_abs.current.classList.add("show")}
				className="down_time_wrapper"
			>
				{data.map((elem, id) => {
					const same_date =
						id != 0 && data[id - 1].start.getDate() == elem.start.getDate();
					return (
						<div
							className={`${elem["time_type"]} down_time_elem ${
								id != 0 && data[id - 1].time_type == elem.time_type
									? "left-same"
									: ""
							} ${
								id != data.length - 1 && data[id + 1].time_type == elem.time_type
									? "right-same"
									: ""
							}`}
							key={id}
							onMouseEnter={(e) => set_absolute_label(e.target, elem)}
						>
							{same_date ? null : (
								<span className="abs-date">
									{elem.start.toLocaleDateString(`fr-FR`, short_date)}
								</span>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default DownTimeGraph;
