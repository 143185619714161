import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter, HashRouter } from "react-router-dom";
import AsideNavbar from "./components/nav/aside_navbar/AsideNavbar";
import Navbar from "./components/nav/navbar/Navbar";
import AuthContext from "./contexts/AuthContext";
import { ConfirmationProvider } from "./contexts/ConfirmationContext";
import { SocketProvider } from "./contexts/SocketContext";
import Log from "./Log";
import Main from "./Main";
import { mapDispatchToProps } from "./store/dispatcher";
import { createTheme, ThemeProvider } from "@mui/material";

const token_ok = {
	token: "",
	dashboard: false,
	devices: false,
};

const App = ({ fetchDevices, fetchMessages, fetchDashboard, fetchBlackListed }) => {
	const { user } = useContext(AuthContext);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (!user.need_reload) return;
		setLoaded(false);
		token_ok.token = "";
		token_ok.devices = false;
		token_ok.dashboard = false;
		if (user.token) {
			token_ok.token = user.account.id;
			fetchBlackListed(user.account.id);
			fetchDashboard(user.account.id).then((e) => {
				token_ok.dashboard = true;
				if (token_ok.devices) setLoaded(true);
			});
			fetchMessages({ account_id: user.account.id });
			fetchDevices(user.account.id).then((e) => {
				token_ok.devices = true;
				if (token_ok.dashboard) setLoaded(true);
			});
		}
	}, [user]);

	let color_theme = localStorage.getItem("theme");
	color_theme = color_theme ? color_theme : "dark";
	const theme = createTheme({
		palette: {
			primary: {
				main: color_theme == "dark" ? "#A4D4B4" : "#5DADE2",
			},
			mode: color_theme,
		},
		components: {
			MuiDrawer: {
				styleOverrides: {
					paper: {
						background: color_theme == "dark" ? "#121212" : "#F2F8FD",
					},
				},
			},
		},
	});

	if (user.token && user.token != "") {
		return (
			<ThemeProvider theme={theme}>
				<HashRouter>
					<Navbar />
					<AsideNavbar />
					{loaded &&
					user.account.id == token_ok.token &&
					token_ok.dashboard &&
					token_ok.devices ? (
						<SocketProvider>
							<ConfirmationProvider>
								<div className="content">
									<Main user={user} />
								</div>
							</ConfirmationProvider>
						</SocketProvider>
					) : null}
				</HashRouter>
			</ThemeProvider>
		);
	}
	return (
		<ThemeProvider theme={theme}>
			<HashRouter>
				<ConfirmationProvider>
					<Log />
				</ConfirmationProvider>
			</HashRouter>
		</ThemeProvider>
	);
};

export default connect(null, mapDispatchToProps)(App);
