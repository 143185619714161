import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { Button } from "@mui/material";
import SocketContext from "../../../contexts/SocketContext";
import ConfirmationContext from "../../../contexts/ConfirmationContext";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";

export const device_status = ["Disconnected", "Running", "Stopped", "Error", "Soft Limit"];

const DeviceDataMonitore = ({ device, disconnectDevice }) => {
	const { send_to_device, get_device_time_diff } = useContext(SocketContext);
	const { user } = useContext(AuthContext);
	const { open_confirm } = useContext(ConfirmationContext);
	const [lastPing, setLastPing] = useState(
		get_device_time_diff(device.id) == -1
			? -1
			: Math.floor(get_device_time_diff(device.id) / 1000)
	);
	const navigate = useNavigate();

	const delete_device = () => {
		disconnectDevice({
			account_id: user.account.id,
			device_id: device.id,
			device: { ...device, down_times: [] },
		});
		navigate("/devices");
	};

	useEffect(() => {
		const interval = setInterval(() => {
			let last_ping = get_device_time_diff(device.id);
			if (last_ping != -1) {
				last_ping = Math.floor(last_ping / 1000);
			}
			setLastPing(last_ping);
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div id="device-data-monitore">
			<div className="device-data">
				<div className="device-data-elem">
					<span>Name</span>
					<span className="data">{device.name}</span>
				</div>
				<div className="device-data-elem">
					<span>Status</span>
					<span className="data">{device_status[parseInt(device.device_status)]}</span>
				</div>
				<div className="device-data-elem">
					<span>Battery</span>
					<span className="data">{device.battery}%</span>
				</div>
				<div className="device-data-elem">
					<span>Last ping</span>
					<span className="data">{lastPing == -1 ? "unknown" : `${lastPing}s ago`}</span>
				</div>
			</div>
			<div className="monitore">
				<h2>Monitore device</h2>
				<div className="field-button">
					<span>Reload device</span>
					<Button
						disabled={device.device_status == "0"}
						size="small"
						onClick={() =>
							open_confirm(
								"Reload device",
								"Reload device to change error status or for any other reason. It will reconnect automatically.",
								"",
								() => send_to_device(`${device.id}|reload`)
							)
						}
						variant="outlined"
					>
						Reload
					</Button>
				</div>
				<h3 className="danger">Danger zone</h3>
				<div className="field-button">
					<span>Disconnect device</span>
					<Button
						size="small"
						onClick={() =>
							open_confirm(
								"Disconnect device",
								"This action is a destructive action. It cannot be undo. Please make sure you know what you are doing.",
								"Disconnect device",
								delete_device
							)
						}
						variant="contained"
						color="error"
					>
						Disconnect
					</Button>
				</div>
			</div>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDataMonitore);
