import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../services/axios";

export const fetchScheduledMessages = createAsyncThunk(
	"scheduledMessages/fetchScheduledMessages",
	async (filters) => {
		const response = await axios.get("/api/scheduled-messages", filters);
		return response.data;
	}
);

export const createScheduledMessage = createAsyncThunk(
	"scheduledMessages/createScheduledMessage",
	async ({ account_id, message, phone_number, schedule_time }) => {
		const response = await axios.post(`/api/scheduled-messages/${account_id}`, {
			message: message,
			phone_number: phone_number,
			schedule_time: schedule_time,
		});
		return response.data;
	}
);

export const deleteScheduledMessage = createAsyncThunk(
	"messages/deleteScheduledMessage",
	async ({ message_id }) => {
		const response = await axios.delete(`/api/scheduled-messages-dashboard/${message_id}`);
		return message_id;
	}
);

const scheduledMessagesSlice = createSlice({
	name: "scheduledMessages",
	initialState: null,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchScheduledMessages.fulfilled, (state, { payload }) => {
			return payload;
		});
		builder.addCase(createScheduledMessage.fulfilled, (state, { payload }) => {
			return [payload, ...state];
		});
		builder.addCase(deleteScheduledMessage.fulfilled, (state, { payload }) => {
			const old = state.filter((elem) => elem.id == payload);
			if (old.length == 0) return state;
			state.splice(state.indexOf(old[0]), 1);
			return state;
		});
	},
});

export const scheduledMessagesMethods = {
	fetchScheduledMessages,
	createScheduledMessage,
	deleteScheduledMessage,
};

export default scheduledMessagesSlice;
