import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import "./style.scss";

const Paginator = ({ fetchMessages, count }) => {
	let [searchParams, setSearchParams] = useSearchParams();
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();
	const page = searchParams.get("page") ? parseInt(searchParams.get("page")) + 1 : 1;

	const navigate_to_page = (page_to_nav) => {
		fetchMessages({ account_id: user.account.id, filters: `?page=${page_to_nav}` });
		setSearchParams({ page: page_to_nav });
	};

	return (
		<div className="paginator">
			<span
				onClick={() => (page == 1 ? null : navigate_to_page(page - 2))}
				className={page == 1 ? "inactive" : ""}
			>
				<i className="fas fa-chevron-left"></i>
			</span>
			<p>{page}</p>
			<span
				onClick={() => (count != 100 ? null : navigate_to_page(page))}
				className={count != 100 ? "inactive" : ""}
			>
				<i className="fas fa-chevron-right"></i>
			</span>
		</div>
	);
};

export default connect(null, mapDispatchToProps)(Paginator);
