import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";
import "./style.scss";
import logo from "../../../assets/logo.png";

const AsideNavbar = () => {
	const location = useLocation();
	const { user, logout } = useContext(AuthContext);

	return (
		<aside className="navigation">
			<nav id="navbar">
				<ul>
					<li className={location.pathname === "/" ? "active hov" : "hov"}>
						<Link to="/">
							<div className="navigation_elem">
								<i className="fas fa-columns"></i>
								<p>Dashboard</p>
							</div>
						</Link>
					</li>
					<li className={location.pathname === "/devices" ? "active hov" : "hov"}>
						<Link to="/devices">
							<div className="navigation_elem">
								<i className="fas fa-mobile"></i>
								<p>Devices</p>
							</div>
						</Link>
					</li>
					<li>
						<div className="separator">
							<h2 className="separator_title">Messages</h2>
						</div>
					</li>
					<li className={location.pathname === "/messages" ? "active hov" : "hov"}>
						<Link to="/messages">
							<div className="navigation_elem">
								<i className="fas fa-comment"></i>
								<p>Messages</p>
							</div>
						</Link>
					</li>
					<li
						className={
							location.pathname === "/scheduled-messages" ? "active hov" : "hov"
						}
					>
						<Link to="/scheduled-messages">
							<div className="navigation_elem">
								<i className="fas fa-calendar-alt"></i>
								<p>Scheduled Messages</p>
							</div>
						</Link>
					</li>
					<li
						className={
							location.pathname === "/black-list-numbers" ? "active hov" : "hov"
						}
					>
						<Link to="/black-list-numbers">
							<div className="navigation_elem">
								<i className="fas fa-bug"></i>
								<p>Blacklisted Numbers</p>
							</div>
						</Link>
					</li>
					<li>
						<div className="separator">
							<h2 className="separator_title">Account</h2>
						</div>
					</li>
					<li
						className={`large ${
							location.pathname.includes("/account-settings") &&
							location.pathname !== "/account-settings/developers"
								? "active hov"
								: "hov"
						}`}
					>
						<Link to="/account-settings">
							<div className="navigation_elem">
								<i className="fas fa-cog"></i>
								<p>Account settings</p>
							</div>
						</Link>
					</li>
					<li
						className={`small ${
							location.pathname.includes("/account-settings") &&
							location.pathname !== "/account-settings/developers"
								? "active hov"
								: "hov"
						}`}
					>
						<Link to="/account-settings/navigation">
							<div className="navigation_elem">
								<i className="fas fa-cog"></i>
								<p>Account settings</p>
							</div>
						</Link>
					</li>
					<li
						className={
							location.pathname === "/account-settings/developers"
								? "active hov"
								: "hov"
						}
					>
						<Link to="/account-settings/developers">
							<div className="navigation_elem">
								<i className="fas fa-bug"></i>
								<p>Developers settings</p>
							</div>
						</Link>
					</li>
					<li>
						<div className="separator">
							<h2 className="separator_title">Disconnect</h2>
						</div>
					</li>
					<li className={location.pathname === "/logout" ? "active hov" : "hov"}>
						<Link to="/" onClick={logout}>
							<div className="navigation_elem">
								<i className="fas fa-sign-out-alt"></i>
								<p>Logout</p>
							</div>
						</Link>
					</li>
				</ul>
			</nav>
		</aside>
	);
};

export default AsideNavbar;
