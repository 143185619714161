import { TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import CreateBox from "../../components/main/create_box/CreateBox";
import Loading from "../../components/main/loading/Loading";
import Table from "../../components/main/table/Table";
import { TOAST_LVL } from "../../constants/constants";
import AuthContext from "../../contexts/AuthContext";
import ConfirmationContext from "../../contexts/ConfirmationContext";
import { set_doc_title } from "../../functions/main";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";

const ScheduledMessages = ({
	scheduled_messages,
	fetchScheduledMessages,
	createScheduledMessage,
	deleteScheduledMessage,
}) => {
	const [newMessage, setNewMessage] = useState(false);
	const { user } = useContext(AuthContext);
	const { setToast } = useContext(ConfirmationContext);
	const new_message_phone = useRef("");
	const new_message_text = useRef("");
	const new_message_date = useRef("");

	useEffect(() => {
		set_doc_title("Scheduled", user.account.name);
		fetchScheduledMessages();
	}, []);

	const send_message = () => {
		if (new_message_phone.current != "" && new_message_text.current != "") {
			createScheduledMessage({
				account_id: user.account.id,
				message: new_message_text.current,
				phone_number: new_message_phone.current,
				schedule_time: new Date(new_message_date.current).toJSON(),
			});
			new_message_text.current = "";
			new_message_phone.current = "";
		}
		setNewMessage(false);
	};

	const delete_bulk = async (ids) => {
		ids.forEach(async (id) => {
			await deleteScheduledMessage({ message_id: id });
		});
		setToast.current(
			TOAST_LVL.SUCCESS,
			"Deletion completed",
			`${ids.length} scheduled message${
				ids.length > 1 ? "s have" : " has"
			} been successfully deleted`
		);
	};

	if (!scheduled_messages) {
		return <Loading />;
	}

	return (
		<div className="table-view">
			<CreateBox
				title={"New message"}
				visible={newMessage}
				cancel={() => setNewMessage(false)}
				submit={() => send_message()}
				submit_text="Create"
				submitable={true}
			>
				<div className="create-box-body">
					<TextField
						size="small"
						type="tel"
						defaultValue={new_message_phone.current}
						onChange={(e) => (new_message_phone.current = e.target.value)}
						fullWidth
						className="value-input"
						id="outlined-basic"
						label="Phone number"
						variant="outlined"
					/>
					<TextField
						size="small"
						type="text"
						defaultValue={new_message_text.current}
						onChange={(e) => (new_message_text.current = e.target.value)}
						fullWidth
						className="value-input"
						id="outlined-basic"
						label="Message"
						variant="outlined"
					/>
					<input
						min={new Date().toISOString().substring(0, 16)}
						className="time-input"
						step="900"
						onInput={(e) => (new_message_date.current = e.target.value)}
						type="datetime-local"
					/>
				</div>
			</CreateBox>
			<Table
				selectable={true}
				new_text="Create schedule"
				new_item={() => setNewMessage(true)}
				title={"Scheduled messages"}
				data={scheduled_messages}
				actions={{
					delete: {
						label: "Delete selection",
						action: delete_bulk,
					},
					more: [
						{
							label: "Download in xls format",
							action: () => console.log("download"),
						},
					],
				}}
				used_columns={[
					{
						column: "id",
						str_column: "id",
						var_type: "str",
					},
					{
						column: "phone_number",
						str_column: "phone number",
						var_type: "str",
					},
					{
						column: "schedule_time",
						str_column: "schedule time",
						var_type: "date-full",
					},
					{
						column: "created",
						str_column: "Created",
						var_type: "card",
						card_value: {
							false: { title: "waiting", color: "var(--pending)" },
							true: { title: "created", color: "var(--success)" },
						},
					},
				]}
			/>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledMessages);
