import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SocketContext from "../../../contexts/SocketContext";
import { device_status } from "../DeviceDataMonitore/DeviceDataMonitore";

const DevicesTable = ({ devices }) => {
	const [lastPing, setLastPing] = useState({});
	const { get_device_time_diff } = useContext(SocketContext);

	useEffect(() => {
		const interval = setInterval(() => {
			const devices_ping = {};
			devices.map((elem) => {
				const last_ping = get_device_time_diff(elem.id);
				if (last_ping != -1) {
					devices_ping[elem.id] = Math.floor(last_ping / 1000);
				}
			});
			setLastPing(devices_ping);
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<table className="device-table">
			<thead>
				<tr>
					<th>Device name</th>
					<th>Status</th>
					<th>Battery</th>
					<th>Last ping</th>
				</tr>
			</thead>
			<tbody>
				{devices.map((device, id) => (
					<tr key={id}>
						<td>
							<Link to={`/devices/${device.id}`}>{device.name}</Link>
						</td>
						<td>
							<Link to={`/devices/${device.id}`}>
								{device_status[parseInt(device.device_status)]}
							</Link>
						</td>
						<td>
							<Link to={`/devices/${device.id}`}>{device.battery}%</Link>
						</td>
						<td>
							<Link to={`/devices/${device.id}`}>
								{!isNaN(lastPing[device.id]) ? lastPing[device.id] : "."} s ago
							</Link>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default DevicesTable;
