import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../services/axios";

export const fetchDashboard = createAsyncThunk("dashboard/fetchDashboard", async (account_id) => {
	if (!account_id) return null;
	const response = await axios.get(`/api/me/dashboard-data/${account_id}`);
	return response.data;
});

const dashboardSlice = createSlice({
	name: "dashboard",
	initialState: null,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchDashboard.fulfilled, (state, { payload }) => {
			return payload;
		});
	},
});

export const dashboardMethods = {
	fetchDashboard,
};

export default dashboardSlice;
