import { Button, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import "./style.scss";
import { LoadingButton } from "@mui/lab";
import axios from "../../../../services/axios";
import AuthContext from "../../../../contexts/AuthContext";
import ConfirmationContext from "../../../../contexts/ConfirmationContext";
import { TOAST_LVL } from "../../../../constants/constants";

const NewKey = ({ close, active, add_key }) => {
	const { user } = useContext(AuthContext);
	const { setToast } = useContext(ConfirmationContext);
	const [key, setKey] = useState(null);
	const [keyName, setKeyName] = useState("");
	const [loading, setLoading] = useState(false);

	const create_key = async () => {
		if (key) {
			navigator.clipboard.writeText(key.key);
			setToast.current(
				TOAST_LVL.SUCCESS,
				"The api key has been copied inside of your clipbord"
			);
			return;
		}
		setLoading(true);
		const ret = await axios
			.post(`/api/accounts/${user.account.id}/keys/${encodeURIComponent(keyName)}`)
			.then((e) => e.data)
			.catch((e) => null);
		if (ret) {
			setKey({ key: ret.key, name: keyName, disabled: false });
		} else {
			setToast.current(TOAST_LVL.ERROR, "This name is already used");
		}
		setLoading(false);
	};

	const close_check = () => {
		if (key) {
			add_key({ name: key.name, disabled: false });
		}
		close();
	};

	if (!active) {
		return null;
	}

	return (
		<div id="new-key-div" className="pair-device">
			<div className="pair-device-center">
				<div className="pair-device-header">
					<h3>New api key</h3>
				</div>
				<div className="pair-device-body">
					{key ? (
						<>
							<p>
								Here is the new api key. Make sure to store it propely because you
								will not be able to retrieve it.
							</p>
							<p className="key-value">{key.key}</p>
						</>
					) : (
						<>
							<p>
								Enter the name of the new api key. The name has to be at least 2
								char and you cannot create two api keys with the same name.
							</p>
							<TextField
								value={keyName}
								onChange={(e) => setKeyName(e.target.value)}
								size="small"
								fullWidth
								label="Api key name"
							/>
						</>
					)}
				</div>
				<div className="pair-device-footer">
					<Button onClick={close_check} variant="outlined">
						{key ? "Close" : "Cancel"}
					</Button>
					<LoadingButton
						loading={loading}
						disabled={keyName == ""}
						onClick={() => create_key()}
						variant="contained"
					>
						{key ? "Copy key" : "Create key"}
					</LoadingButton>
				</div>
			</div>
		</div>
	);
};

export default NewKey;
