import { Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { socketURL } from './constants/constants';

const Test = () => {
	const ws = useRef(null);
	const device_ws = useRef(null);
	const [sending, setSending] = useState(false);
	const new_dev_ws = useRef(null);

	useEffect(() => {
		ws.current = new WebSocket(`ws://localhost:3012/ws?account_id=8b5fa1ce-69e6-4d56-b877-d3df7255934d`);
		ws.current.onopen = () => {console.log("dashboard")}
		ws.current.onclose = () => {console.log("dashboard close")}
		ws.current.onerror = (e) => {console.log(e);ws.close()}
		ws.current.onmessage = (msg) => {
			console.log("dashboard get: ", msg)
		}

		device_ws.current = new WebSocket(`ws://localhost:3012/ws?account_id=8b5fa1ce-69e6-4d56-b877-d3df7255934d&device_id=coucou&exts=32,33`);
		device_ws.current.onopen = () => {
			console.log("device open")
			device_ws.current.send("Bearer eyJhbGciOiJIUzI1NiJ9.eyJpZCI6ImQ5YTE1MzhhLWNjZmQtNGRhYy05ZmJjLWM2MjZlNWU5YmRhMSJ9.uV9Par7CbmzJBSn06TXNU1eWwGErCR7vUQrTn675XgY");
		}
		device_ws.current.onclose = () => {console.log("device close")}
		device_ws.current.onerror = (e) => {console.log(e);device_ws.current.close()}
		device_ws.current.onmessage = (msg) => {
			console.log("device get: ", msg)
		}

		// new_dev_ws.current = new WebSocket(`ws://localhost:3012/ws?account_id=8b5fa1ce-69e6-4d56-b877-d3df7255934d&device_id=coucou`);
		// new_dev_ws.current.onopen = () => {
		// 	console.log("device open")
		// 	new_dev_ws.current.send("Bearer eyJhbGciOiJIUzI1NiJ9.eyJpZCI6ImQ5YTE1MzhhLWNjZmQtNGRhYy05ZmJjLWM2MjZlNWU5YmRhMSJ9.uV9Par7CbmzJBSn06TXNU1eWwGErCR7vUQrTn675XgY");
		// }
		// new_dev_ws.current.onclose = () => {console.log("device close")}
		// new_dev_ws.current.onerror = (e) => {console.log(e);new_dev_ws.current.close()}
		// new_dev_ws.current.onmessage = (msg) => {
		// 	console.log("device get: ", msg)
		// }

		// setTimeout(() => {
		// 	device_ws.current.close();
		// 	device_ws.current = null;
		// }, 1000);

		// setTimeout(() => {
		// 	new_dev_ws.current.close();
		// 	new_dev_ws.current = null;
		// }, 5000);
	
		return () => {
			if (ws.current)
				ws.current.close()
			if (device_ws.current)
				device_ws.current.close()
			// if (new_dev_ws.current)
			// 	new_dev_ws.current.close()
		}
	}, [])

	const handle_action = () => {
		if (device_ws.current) {
			device_ws.current.send("sending");
			setSending(true)
		}
	}

	const handle_device_aciton = () => {
		if (device_ws.current){
			device_ws.current.send("sent");
			setSending(false)
		}
	}
	
	return (
		<div>
			<p>{sending ? "en envoie" : "dispo"}</p>
			<Button variant='outlined' onClick={handle_action} >Set device as sending</Button>
			<Button variant='outlined' onClick={handle_device_aciton} >Unsending</Button>
		</div>
	)
}

export default Test