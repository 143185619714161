import React, { useContext, useEffect, useState } from "react";
import axios from "../../../services/axios";
import AuthContext from "../../../contexts/AuthContext";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import "./style.scss";
import Switch from "@mui/material/Switch";
import ConfirmationContext from "../../../contexts/ConfirmationContext";
import { Button } from "@mui/material";
import NewKey from "./new_key/NewKey";

const ApiKeys = () => {
	const { user } = useContext(AuthContext);
	const [apiKeys, setApiKeys] = useState([]);
	const { open_confirm } = useContext(ConfirmationContext);
	const [createKey, setCreateKey] = useState(false);

	const load = async () => {
		const ret = await axios
			.get(`/api/accounts/${user.account.id}/keys`)
			.then((e) => e.data)
			.catch((e) => null);
		if (ret) {
			setApiKeys(ret);
		}
	};

	const add_key = (new_key) => {
		setApiKeys([...apiKeys, new_key]);
	};

	const toggle_check = async (name) => {
		const ret = await axios
			.put(`/api/accounts/${user.account.id}/keys/${encodeURIComponent(name)}`)
			.then((e) => e.data)
			.catch((e) => null);
		if (ret) {
			let api_keys = [...apiKeys];
			let correct = api_keys.filter((elem) => elem.name == name);
			if (correct.length != 0) {
				const new_item = { ...correct[0], blocked: ret.is_blocked };
				api_keys.splice(api_keys.indexOf(correct[0]), 1, new_item);
				setApiKeys(api_keys);
			}
		}
	};

	const delete_key = async (name) => {
		const ret = await axios
			.delete(`/api/accounts/${user.account.id}/keys/${encodeURIComponent(name)}`)
			.then((e) => e.data)
			.catch((e) => null);
		if (ret) {
			let api_keys = [...apiKeys];
			let correct = api_keys.filter((elem) => elem.name == name);
			if (correct.length != 0) {
				api_keys.splice(api_keys.indexOf(correct[0]), 1);
				setApiKeys(api_keys);
			}
		}
	};

	const switch_status = (next_status, name) => {
		if (next_status) {
			open_confirm(
				`Activate api key "${name}"`,
				"By reactivating this key all services using this key will be able to communicate with the api.",
				"",
				() => toggle_check(name)
			);
		} else {
			open_confirm(
				`Deactivate api key "${name}"`,
				"By deactivating this key all services using this key will not be able to communicate with the api.",
				"",
				() => toggle_check(name)
			);
		}
	};

	useEffect(() => {
		load();
	}, []);

	return (
		<div id="api-key-table" className="setting-block-description full-width">
			<h2>Api keys</h2>
			<p>
				Those api keys will allow you to authenticate API request.{" "}
				<a target="_blank" href="https://docs.smsdispatcher.app">
					Learn more
				</a>
			</p>
			<div className="table-container-api">
				<table>
					<thead>
						<tr>
							<th align="start">Name</th>
							<th align="start">Status</th>
							<th className="delete">
								<Button onClick={() => setCreateKey(true)} variant="outlined">
									New key
								</Button>
							</th>
						</tr>
					</thead>
					<tbody>
						{apiKeys.map((elem, id) => (
							<tr key={id}>
								<td>{elem.name}</td>
								<td>
									<Switch
										onChange={() => switch_status(elem.blocked, elem.name)}
										checked={!elem.blocked}
									/>
								</td>
								<td
									onClick={() =>
										open_confirm(
											`Delete api key "${elem.name}"`,
											"This is a destructive action. Every services using this key may have trouble to work after this action. Make sure you know what you are doing.",
											`Delete "${elem.name}"`,
											() => delete_key(elem.name)
										)
									}
									className="delete"
									align="end"
								>
									<DeleteOutlineIcon />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<NewKey add_key={add_key} close={() => setCreateKey(false)} active={createKey} />
		</div>
	);
};

export default ApiKeys;
