import React, { useContext, useEffect, useRef } from "react";
import { createContext } from "react";
import { connect } from "react-redux";
import { socketURL } from "../constants/constants";
import { mapDispatchToProps, mapStateToProps } from "../store/dispatcher";
import AuthContext from "./AuthContext";

const SocketContext = createContext();

let ping_interval = null;

export const SocketProvider = connect(
	null,
	mapDispatchToProps
)(({ children, updateDeviceHand }) => {
	const { user } = useContext(AuthContext);
	const ws = useRef(null);
	const device_map = useRef({});

	const get_device_time_diff = (device_id) => {
		if (device_map.current[device_id]) {
			return Date.now() - device_map.current[device_id];
		} else {
			return -1;
		}
	};

	const send_to_device = (msg) => {
		if (ws.current) ws.current.send(msg);
	};

	const connect_ws = () => {
		if (ws.current) {
			ws.current.close();
			ws.current = null;
		}
		ws.current = new WebSocket(`${socketURL}/ws?account_id=${user.account.id}`);
		ws.current.onopen = () => {
			console.log("main ws open");
			ping_interval = setInterval(() => {
				if (ws.current) ws.current.send("ping");
			}, 1000);
		};
		ws.current.onerror = (e) => {
			ws.current.close();
			ws.current = null;
			clearInterval(ping_interval);
		};
		ws.current.onclose = (e) => {
			console.log("main ws closed");
			ws.current = null;
		};
		ws.current.onmessage = (m) => {
			console.log("Main socket message: ", m);
			if (m.data.endsWith("ping") && m.data.includes("|")) {
				const device = m.data.split("|")[0];
				device_map.current[device] = Date.now();
			} else if (m.data.endsWith("up") && m.data.includes("|")) {
				updateDeviceHand({ device_status: "1", id: m.data.split("|")[0] });
			} else if (m.data.endsWith("down") && m.data.includes("|")) {
				updateDeviceHand({ device_status: "0", id: m.data.split("|")[0] });
			}
		};
	};

	useEffect(() => {
		connect_ws();
		return () => {
			clearInterval(ping_interval);
			if (ws.current) ws.current.close();
		};
	}, []);

	return (
		<SocketContext.Provider
			value={{
				get_device_time_diff,
				send_to_device,
			}}
		>
			{children}
		</SocketContext.Provider>
	);
});

export default SocketContext;
