import { Button } from "@mui/material";
import React, { useContext } from "react";
import "./style.scss";
import CheckIcon from "@mui/icons-material/Check";
import AuthContext from "../../contexts/AuthContext";
import axios from "../../services/axios";
import ConfirmationContext from "../../contexts/ConfirmationContext";
import { useNavigate } from "react-router-dom";
import { TOAST_LVL } from "../../constants/constants";

const PricingPlan = () => {
	const { user, setup } = useContext(AuthContext);
	const { open_confirm, setToast } = useContext(ConfirmationContext);
	const next_month = window.location.href.includes("next");
	const current_plan = next_month ? user.account.next_month_plan : user.account.current_plan;
	const navigate = useNavigate();

	const select_current_plan = async (plan, is_lower) => {
		if (is_lower) {
			await axios
				.put(`/api/accounts/${user.account.id}/plan`, { next_month_plan: plan })
				.then((e) => e.data)
				.catch((e) => null);
			setup();
		} else {
			await axios
				.put(`/api/accounts/${user.account.id}/plan`, {
					current_plan: plan,
					next_month_plan: plan,
				})
				.then((e) => e.data)
				.catch((e) => null);
			setup();
		}
		navigate(-1);
	};

	const select_plan = async (plan) => {
		if (next_month) {
			await axios
				.put(`/api/accounts/${user.account.id}/plan`, { next_month_plan: plan })
				.then((e) => e.data)
				.catch((e) => null);
			setup();
			navigate(-1);
		} else {
			const is_greater = plan > user.account.current_plan;
			open_confirm(
				"Change current plan",
				is_greater
					? "This plan is more expensive than your current plan. If you select it you will pay the difference between your current plan price and the new plan price."
					: "This plan is less expensive than your current plan. It will therefore be selected for the next month but for this month you will still have the plan you have paid for.",
				"",
				() => select_current_plan(plan, !is_greater)
			);
		}
	};

	return (
		<div id="pricing-plan-view">
			<div className="pricing-plan-header">
				<h1>{next_month ? "Next month plan" : "Plans & pricing"}</h1>
				<p>
					{next_month
						? "Select plan that will be automaticly selected next month. If you choose the free testing message will be send."
						: "Simple pricing, no hidden fees"}
				</p>
			</div>
			<div className="pricing-plan-wrapper">
				<article>
					<div className="pricing-elem-header">
						<h2>Testing</h2>
						<p>Perfect plan to setup and test</p>
					</div>
					<div className="pricing-elem-price">
						<span>Free</span>
					</div>
					<Button
						onClick={() => select_plan("0")}
						fullWidth
						variant={current_plan == "0" ? "outlined" : "contained"}
						disabled={current_plan == "0"}
					>
						{current_plan == "0"
							? next_month
								? "Next month plan"
								: "Current plan"
							: "Select this plan"}
					</Button>
					<div className="pricing-elem-data">
						<div className="pricing-data-item">
							<CheckIcon /> <p>Full api access</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>Dashboard and android app access</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>Testing sms</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>1 device</p>
						</div>
					</div>
				</article>
				<article>
					<div className="pricing-elem-header">
						<h2>Basic</h2>
						<p>Perfect plan for small business</p>
					</div>
					<div className="pricing-elem-price">
						<span>13$</span>
					</div>
					<Button
						onClick={() => select_plan("1")}
						fullWidth
						variant={current_plan == "1" ? "outlined" : "contained"}
						disabled={current_plan == "1"}
					>
						{current_plan == "1"
							? next_month
								? "Next month plan"
								: "Current plan"
							: "Select this plan"}
					</Button>
					<div className="pricing-elem-data">
						<div className="pricing-data-item">
							<CheckIcon /> <p>Full api access</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>Dashboard and android app access</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>1000 sms</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>1 device</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>Scheduled sms</p>
						</div>
					</div>
				</article>
				<article className="revert">
					<div className="pricing-elem-header">
						<h2>Plus</h2>
						<p>Recommended plan</p>
					</div>
					<div className="pricing-elem-price">
						<span>26$</span>
					</div>
					<Button
						onClick={() => select_plan("2")}
						className={current_plan == "2" ? "selected" : ""}
						color="inherit"
						fullWidth
						variant={current_plan == "2" ? "outlined" : "contained"}
						disabled={current_plan == "2"}
					>
						{current_plan == "2"
							? next_month
								? "Next month plan"
								: "Current plan"
							: "Select this plan"}
					</Button>
					<div className="pricing-elem-data">
						<div className="pricing-data-item">
							<CheckIcon /> <p>Full api access</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>Dashboard and android app access</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>4000 sms</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>1 device</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>Scheduled sms</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>Dual sim usage</p>
						</div>
					</div>
				</article>
				<article>
					<div className="pricing-elem-header">
						<h2>Pro</h2>
						<p>Recommended plan</p>
					</div>
					<div className="pricing-elem-price">
						<span>46$</span>
					</div>
					<Button
						onClick={() => select_plan("3")}
						fullWidth
						variant={current_plan == "3" ? "outlined" : "contained"}
						disabled={current_plan == "3"}
					>
						{current_plan == "3"
							? next_month
								? "Next month plan"
								: "Current plan"
							: "Select this plan"}
					</Button>
					<div className="pricing-elem-data">
						<div className="pricing-data-item">
							<CheckIcon /> <p>Full api access</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>Dashboard and android app access</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>no sms limit</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>1 device</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>Scheduled sms</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>Dual sim usage</p>
						</div>
						<div className="pricing-data-item">
							<CheckIcon /> <p>Device balancer</p>
						</div>
					</div>
				</article>
			</div>
		</div>
	);
};

export default PricingPlan;
