import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";

const TakeOver = () => {
	const { user_id, session_time } = useParams();
	const { take_over } = useContext(AuthContext);
	const navigate = useNavigate();

	const make_take_over = async () => {
		await take_over(user_id, session_time ? session_time : "3m");
		navigate("/");
	};

	useEffect(() => {
		make_take_over();
	}, []);

	return <div>TakeOver</div>;
};

export default TakeOver;
