import React, { useEffect, useRef } from "react";
import { createContext, useState } from "react";
import { getData, getSpecificData, storeData } from "../functions/store_data";
import axios, { set_instance_token, unset_instance_token } from "../services/axios";

const AuthContext = createContext();

const default_user = {
	first_name: "Anonymous",
	last_name: "User",
	token: null,
	account: {},
	need_reload: true,
};

export const AuthProvider = (props) => {
	const [user, setUser] = useState({ ...default_user });
	const [refresh, setRefresh] = useState(false);
	const accounts = useRef(null);

	const fetch_accounts = async (id) => {
		const need = await axios
			.get("/api/accounts")
			.then((response) => {
				accounts.current = response.data;
				setRefresh((refresh) => !refresh);
				return true;
			})
			.catch((e) => {
				if (e.response?.status == 401) {
					logout();
				}
				accounts.current = [];
				return false;
			});
		if (id && need) {
			const ret = await axios
				.get(`/api/accounts/${id}`)
				.then((response) => {
					return response.data;
				})
				.catch((e) => null);
			if (ret) {
				return ret;
			}
		}

		return null;
	};

	const fetch_account = async (id) => {
		const ret = await axios
			.get(`/api/accounts/${id}`)
			.then((response) => {
				return response.data;
			})
			.catch((e) => {
				console.log(e);
				return null;
			});
		if (ret) {
			if (user.account.id == ret.id) setUser({ ...user, account: ret });
			else setUser({ ...user });
		}
		return ret;
	};

	const select_account = async (id) => {
		accounts.current.map((elem) => {
			if (elem.id == id) {
				storeData("account", elem);
				setUser({ ...user, need_reload: true, account: elem });
			}
		});
	};

	const update_account = async (name, code) => {
		const account = getData();
		accounts.current.map((e) => {
			if (e.id == account.account.id) {
				e.name = name;
				e.dialing_code = code;
			}
		});
		storeData("account", { ...account.account, name: name, dialing_code: code });
		setUser({
			...user,
			account: { ...account.account, name: name, dialing_code: code },
			need_reload: true,
		});
	};

	const get_accounts = () => {
		return accounts.current;
	};

	const profile = async () => {
		const ret = await axios
			.get("/api/me")
			.then((e) => e.data)
			.catch((e) => null);
		return ret;
	};

	const login_setup_account = async (token, name) => {
		set_instance_token(token);
		storeData("token", token);
		const ret = await profile();
		if (ret) {
			const usr = { ...default_user, ...ret, token: token, need_reload: true };
			const account = await axios
				.post("/api/accounts", { name: name })
				.then((response) => response.data)
				.catch((e) => {
					console.log(e);
					accounts.current = [];
					return null;
				});
			if (account) {
				storeData("account", { ...account });
				usr.account = { ...account };
				accounts.current = [account];
				setUser(usr);
				return true;
			}
			return false;
		}
		return false;
	};

	const create_account = async (name) => {
		await axios
			.post("/api/accounts", { name: name })
			.then((response) => {
				accounts.current.push(response.data);
				storeData("account", response.data);
				setUser({ ...user, need_reload: true, account: response.data });
			})
			.catch((e) => {
				console.log(e);
				accounts.current = [];
			});
	};

	const login = async (email, password) => {
		const usr = await axios
			.post("/auth/login", {
				email: email,
				password: password,
			})
			.then((response) => {
				const token = response.data;
				set_instance_token(token);
				storeData("token", token);
				fetch_accounts();
				setUser({ ...user, token: token, need_reload: true, account: {} });
				return { ...user, token: token, need_reload: true, account: {} };
			})
			.catch((e) => {
				console.log(e);
				return null;
			});
		return usr;
	};

	const setup = async () => {
		const app_data = getData();
		if (app_data.token) {
			set_instance_token(app_data.token);
			const me = await axios
				.get("/api/me")
				.then((e) => e.data)
				.catch((e) => null);
			if (!me) {
				if (getSpecificData("old-token")) {
					unset_instance_token();
					const theme = localStorage.getItem("theme");
					const current_account = localStorage.getItem("old-account");
					const token = getSpecificData("old-token");
					localStorage.clear();
					localStorage.setItem("theme", theme);
					localStorage.setItem("account", current_account);
					storeData("token", token);
					setup();
				} else {
					logout();
				}
				return;
			}
			const usr = { ...user, token: app_data.token, need_reload: true, ...me };

			if (app_data.account) {
				usr.account = app_data.account;
			}
			const account = await fetch_accounts(usr.account?.id);
			if (account) {
				usr.account = account;
				setUser(usr);
			} else {
				setUser(usr);
			}
		} else {
			accounts.current = [];
			setUser({
				first_name: "Anonymous",
				last_name: "User",
				token: "",
				account: {},
				need_reload: true,
			});
		}
	};

	const take_over = async (user_id, session_time) => {
		const current_token = user.token;
		const current_account = localStorage.getItem("account");
		const theme = localStorage.getItem("theme");
		const ret = await axios
			.post(`/api/admin/users/${user_id}`, { session_time: session_time })
			.then((e) => e.data)
			.catch((e) => null);
		if (ret) {
			localStorage.clear();
			localStorage.setItem("theme", theme);
			localStorage.setItem("old-account", current_account);
			storeData("token", ret);
			storeData("old-token", current_token);
			set_instance_token(ret);
			const prof = await profile();
			if (prof) {
				fetch_accounts();
				setUser({ ...user, token: ret, need_reload: true, account: {} });
			}
		}
	};

	const take_down = async () => {
		const theme = localStorage.getItem("theme");
		const current_account = localStorage.getItem("old-account");
		const token = getSpecificData("old-token");
		localStorage.clear();
		localStorage.setItem("theme", theme);
		localStorage.setItem("account", current_account);
		storeData("token", token);
		set_instance_token(token);
		const prof = await profile();
		if (prof) {
			fetch_accounts();
			setUser({
				...user,
				token: token,
				need_reload: true,
				account: getSpecificData("account"),
			});
		}
	};

	const toggle_theme = (theme) => {
		setUser({ ...user, theme: theme, need_reload: false });
	};

	useEffect(() => {
		setup();
	}, []);

	const verify_email = async (email, password) => {};

	const register = async () => {};

	const logout = async (data) => {
		setUser({
			first_name: "Anonymous",
			last_name: "User",
			token: "",
			account_id: "",
		});
		accounts.current = [];
		const theme = localStorage.getItem("theme");
		localStorage.clear();
		localStorage.setItem("theme", theme);
		unset_instance_token();
	};

	if (user.token == null) return null;
	if (accounts.current == null && user.token) return null;

	return (
		<AuthContext.Provider
			value={{
				user,
				accounts,
				login,
				verify_email,
				register,
				logout,
				select_account,
				get_accounts,
				create_account,
				toggle_theme,
				update_account,
				login_setup_account,
				setup,
				fetch_account,
				take_over,
				take_down,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
