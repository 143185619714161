import { blackListMethods } from "./slices/black_list";
import { dashboardMethods } from "./slices/dashboard";
import { devicesMethods } from "./slices/devices";
import { messagesMethods } from "./slices/messages";
import { scheduledMessagesMethods } from "./slices/scheduled_messages";

export const mapDispatchToProps = {
	...messagesMethods,
	...blackListMethods,
	...scheduledMessagesMethods,
	...devicesMethods,
	...dashboardMethods,
};

export const mapStateToProps = (state) => state;
