import { Button } from "@mui/material";
import React from "react";
import "./style.scss";

const TableHeader = ({ title, need_action = false, new_text, new_action }) => {
	return (
		<div className="table-header">
			<h1>{title}</h1>
			<div>
				<Button variant="outlined" onClick={new_action}>
					{new_text}
				</Button>
			</div>
		</div>
	);
};

export default TableHeader;
