import React, { useContext, useState } from "react";
import "./style.scss";
import DefaultView from "../../../components/account_settings/default_view/DefaultView";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import AuthContext from "../../../contexts/AuthContext";
import ConfirmationContext from "../../../contexts/ConfirmationContext";
import axios from "../../../services/axios";
import { TOAST_LVL } from "../../../constants/constants";

const phone_numbers = [
	["United States", "US", "+1"],
	["Russian Federation", "RU", "+7"],
	["Egypt", "EG", "+20"],
	["South Africa", "ZA", "+27"],
	["Greece", "GR", "+30"],
	["Netherlands", "NL", "+31"],
	["Belgium", "BE", "+32"],
	["France", "FR", "+33"],
	["Spain", "ES", "+34"],
	["Hungary", "HU", "+36"],
	["Italy", "IT", "+39"],
	["Romania", "RO", "+40"],
	["Switzerland", "CH", "+41"],
	["Austria", "AT", "+43"],
	["United Kingdom", "GB", "+44"],
	["Denmark", "DK", "+45"],
	["Sweden", "SE", "+46"],
	["Norway", "NO", "+47"],
	["Poland", "PL", "+48"],
	["Germany", "DE", "+49"],
	["Peru", "PE", "+51"],
	["Mexico", "MX", "+52"],
	["Cuba", "CU", "+53"],
	["Argentina", "AR", "+54"],
	["Brazil", "BR", "+55"],
	["Chile", "CL", "+56"],
	["Colombia", "CO", "+57"],
	["Venezuela, Bolivarian Republic of", "VE", "+58"],
	["Malaysia", "MY", "+60"],
	["Australia", "AU", "+61"],
	["Indonesia", "ID", "+62"],
	["Philippines", "PH", "+63"],
	["New Zealand", "NZ", "+64"],
	["Singapore", "SG", "+65"],
	["Thailand", "TH", "+66"],
	["Japan", "JP", "+81"],
	["Korea, Republic of", "KR", "+82"],
	["Viet Nam", "VN", "+84"],
	["China", "CN", "+86"],
	["Turkey", "TR", "+90"],
	["India", "IN", "+91"],
	["Pakistan", "PK", "+92"],
	["Afghanistan", "AF", "+93"],
	["Sri Lanka", "LK", "+94"],
	["Myanmar", "MM", "+95"],
	["Iran, Islamic Republic of", "IR", "+98"],
	["South Sudan", "SS", "+211"],
	["Morocco", "MA", "+212"],
	["Algeria", "DZ", "+213"],
	["Tunisia", "TN", "+216"],
	["Libya", "LY", "+218"],
	["Gambia", "GM", "+220"],
	["Senegal", "SN", "+221"],
	["Mauritania", "MR", "+222"],
	["Mali", "ML", "+223"],
	["Guinea", "GN", "+224"],
	["Côte d'Ivoire", "CI", "+225"],
	["Burkina Faso", "BF", "+226"],
	["Niger", "NE", "+227"],
	["Togo", "TG", "+228"],
	["Benin", "BJ", "+229"],
	["Mauritius", "MU", "+230"],
	["Liberia", "LR", "+231"],
	["Sierra Leone", "SL", "+232"],
	["Ghana", "GH", "+233"],
	["Nigeria", "NG", "+234"],
	["Chad", "TD", "+235"],
	["Central African Republic", "CF", "+236"],
	["Cameroon", "CM", "+237"],
	["Cabo Verde", "CV", "+238"],
	["Sao Tome and Principe", "ST", "+239"],
	["Equatorial Guinea", "GQ", "+240"],
	["Gabon", "GA", "+241"],
	["Congo", "CG", "+242"],
	["Congo, The Democratic Republic of the", "CD", "+243"],
	["Angola", "AO", "+244"],
	["Guinea-Bissau", "GW", "+245"],
	["British Indian Ocean Territory", "IO", "+246"],
	["Seychelles", "SC", "+248"],
	["Sudan", "SD", "+249"],
	["Rwanda", "RW", "+250"],
	["Ethiopia", "ET", "+251"],
	["Somalia", "SO", "+252"],
	["Djibouti", "DJ", "+253"],
	["Kenya", "KE", "+254"],
	["Tanzania, United Republic of", "TZ", "+255"],
	["Uganda", "UG", "+256"],
	["Burundi", "BI", "+257"],
	["Mozambique", "MZ", "+258"],
	["Zambia", "ZM", "+260"],
	["Madagascar", "MG", "+261"],
	["Réunion", "RE", "+262"],
	["Zimbabwe", "ZW", "+263"],
	["Namibia", "NA", "+264"],
	["Malawi", "MW", "+265"],
	["Lesotho", "LS", "+266"],
	["Botswana", "BW", "+267"],
	["Eswatini", "SZ", "+268"],
	["Comoros", "KM", "+269"],
	["Saint Helena, Ascension and Tristan da Cunha", "SH", "+290"],
	["Eritrea", "ER", "+291"],
	["Aruba", "AW", "+297"],
	["Faroe Islands", "FO", "+298"],
	["Greenland", "GL", "+299"],
	["Gibraltar", "GI", "+350"],
	["Portugal", "PT", "+351"],
	["Luxembourg", "LU", "+352"],
	["Ireland", "IE", "+353"],
	["Iceland", "IS", "+354"],
	["Albania", "AL", "+355"],
	["Malta", "MT", "+356"],
	["Cyprus", "CY", "+357"],
	["Finland", "FI", "+358"],
	["Bulgaria", "BG", "+359"],
	["Lithuania", "LT", "+370"],
	["Latvia", "LV", "+371"],
	["Estonia", "EE", "+372"],
	["Moldova, Republic of", "MD", "+373"],
	["Armenia", "AM", "+374"],
	["Belarus", "BY", "+375"],
	["Andorra", "AD", "+376"],
	["Monaco", "MC", "+377"],
	["San Marino", "SM", "+378"],
	["Ukraine", "UA", "+380"],
	["Serbia", "RS", "+381"],
	["Montenegro", "ME", "+382"],
	["Croatia", "HR", "+385"],
	["Slovenia", "SI", "+386"],
	["Bosnia and Herzegovina", "BA", "+387"],
	["North Macedonia", "MK", "+389"],
	["Czechia", "CZ", "+420"],
	["Slovakia", "SK", "+421"],
	["Liechtenstein", "LI", "+423"],
	["Falkland Islands (Malvinas)", "FK", "+500"],
	["Belize", "BZ", "+501"],
	["Guatemala", "GT", "+502"],
	["El Salvador", "SV", "+503"],
	["Honduras", "HN", "+504"],
	["Nicaragua", "NI", "+505"],
	["Costa Rica", "CR", "+506"],
	["Panama", "PA", "+507"],
	["Saint Pierre and Miquelon", "PM", "+508"],
	["Haiti", "HT", "+509"],
	["Guadeloupe", "GP", "+590"],
	["Bolivia, Plurinational State of", "BO", "+591"],
	["Guyana", "GY", "+592"],
	["Ecuador", "EC", "+593"],
	["French Guiana", "GF", "+594"],
	["Paraguay", "PY", "+595"],
	["Martinique", "MQ", "+596"],
	["Suriname", "SR", "+597"],
	["Uruguay", "UY", "+598"],
	["Curaçao", "CW", "+599"],
	["Timor-Leste", "TL", "+670"],
	["Norfolk Island", "NF", "+672"],
	["Brunei Darussalam", "BN", "+673"],
	["Nauru", "NR", "+674"],
	["Papua New Guinea", "PG", "+675"],
	["Tonga", "TO", "+676"],
	["Solomon Islands", "SB", "+677"],
	["Vanuatu", "VU", "+678"],
	["Fiji", "FJ", "+679"],
	["Palau", "PW", "+680"],
	["Wallis and Futuna", "WF", "+681"],
	["Cook Islands", "CK", "+682"],
	["Niue", "NU", "+683"],
	["Samoa", "WS", "+685"],
	["Kiribati", "KI", "+686"],
	["New Caledonia", "NC", "+687"],
	["Tuvalu", "TV", "+688"],
	["French Polynesia", "PF", "+689"],
	["Tokelau", "TK", "+690"],
	["Micronesia, Federated States of", "FM", "+691"],
	["Marshall Islands", "MH", "+692"],
	["Korea, Democratic People's Republic of", "KP", "+850"],
	["Hong Kong", "HK", "+852"],
	["Macao", "MO", "+853"],
	["Cambodia", "KH", "+855"],
	["Lao People's Democratic Republic", "LA", "+856"],
	["Bangladesh", "BD", "+880"],
	["Taiwan, Province of China", "TW", "+886"],
	["Maldives", "MV", "+960"],
	["Lebanon", "LB", "+961"],
	["Jordan", "JO", "+962"],
	["Syrian Arab Republic", "SY", "+963"],
	["Iraq", "IQ", "+964"],
	["Kuwait", "KW", "+965"],
	["Saudi Arabia", "SA", "+966"],
	["Yemen", "YE", "+967"],
	["Oman", "OM", "+968"],
	["Palestine, State of", "PS", "+970"],
	["United Arab Emirates", "AE", "+971"],
	["Israel", "IL", "+972"],
	["Bahrain", "BH", "+973"],
	["Qatar", "QA", "+974"],
	["Bhutan", "BT", "+975"],
	["Mongolia", "MN", "+976"],
	["Nepal", "NP", "+977"],
	["Tajikistan", "TJ", "+992"],
	["Turkmenistan", "TM", "+993"],
	["Azerbaijan", "AZ", "+994"],
	["Georgia", "GE", "+995"],
	["Kyrgyzstan", "KG", "+996"],
	["Uzbekistan", "UZ", "+998"],
];

const GeneralAccountSettings = () => {
	const { t, i18n } = useTranslation("account_settings");
	const { user, update_account } = useContext(AuthContext);
	const { setToast } = useContext(ConfirmationContext);

	const [settingsData, setSettingsData] = useState({
		name: user.account.name,
		prefix: user.account.dialing_code,
	});

	const handle_change = (e) => {
		setSettingsData({ ...settingsData, prefix: e.target.value });
	};

	const save = async () => {
		const response = await axios
			.put(`/api/accounts/${user.account.id}`, {
				name: settingsData.name,
				dialing_code: settingsData.prefix,
			})
			.then((e) => e.data)
			.catch((e) => null);
		if (response) {
			update_account(response.name, response.dialing_code);
		}
	};

	const reset = () => {
		setSettingsData({
			name: user.account.name,
			prefix: user.account.dialing_code,
		});
	};

	const valid = () => {
		return (
			settingsData.name != user.account.name ||
			settingsData.prefix != user.account.dialing_code
		);
	};

	return (
		<DefaultView save={save} reset={reset} is_valid={valid}>
			<div className="settings-content">
				<div className="settings-center">
					<div className="setting-block-title">
						<h1>{t("General settings", { account_name: user.account.name })}</h1>
						<p>{t("Change how account work", { account_name: user.account.name })}</p>
					</div>
					<div className="setting-block">
						<div className="setting-block-description">
							<h2>{t("Account name")}</h2>
							<p>{t("Account name explaination")}</p>
						</div>
						<div className="setting-block-content">
							<TextField
								sx={{ minWidth: "240px" }}
								id="outlined-basic"
								value={settingsData.name}
								onChange={(e) =>
									setSettingsData({ ...settingsData, name: e.target.value })
								}
								label={t("Account name")}
								variant="outlined"
							/>
						</div>
					</div>
					<div className="setting-block">
						<div className="setting-block-description">
							<h2>{t("Default prefix")}</h2>
							<p>{t("Default prefix explaination")}</p>
						</div>
						<div className="setting-block-content">
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">Phone prefix</InputLabel>
								<Select
									sx={{ minWidth: "240px" }}
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={settingsData.prefix}
									label={"Phone prefix"}
									onChange={handle_change}
								>
									{phone_numbers.map((elem, id) => (
										<MenuItem key={id} value={elem[1]}>
											{elem[0]} ({elem[2]})
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
					</div>
				</div>
			</div>
		</DefaultView>
	);
};

export default GeneralAccountSettings;
