import React, { useEffect } from "react";
import { useState } from "react";
import BillingTable from "../../../components/account_settings/billing_table/BillingTable";
import DefaultView from "../../../components/settings/default_view/DefaultView";
import axios from "../../../services/axios";

const Billings = () => {
	const [invoices, setInvoices] = useState([]);

	const load_invoices = async () => {
		const response = await axios
			.get(`/api/me/invoices-data`)
			.then((e) => e.data)
			.catch((e) => null);
		if (response) {
			setInvoices(response);
		}
	};

	useEffect(() => {
		load_invoices();
	}, []);

	return (
		<DefaultView>
			<div className="settings-content">
				<div className="settings-center">
					<div className="setting-block-title">
						<h1>Billings</h1>
						<p>Overview of invoices for all of your accounts.</p>
					</div>
					<div className="billing-invoices-wrapper">
						{invoices.map((elem, id) => (
							<BillingTable
								key={id}
								link_id={elem.id}
								invoices={elem.payments}
								title={elem.account_name}
							/>
						))}
					</div>
				</div>
			</div>
		</DefaultView>
	);
};

export default Billings;
