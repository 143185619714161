export const storeData = (key, value) => {
	const jsonValue = JSON.stringify(value);
	localStorage.setItem(key, jsonValue);
};

export const getSpecificData = (key) => {
	const jsonValue = localStorage.getItem(key);
	return jsonValue != null ? JSON.parse(jsonValue) : null;
};

export const getData = () => {
	let get_dict = {
		token: null,
		account: null,
		register_step: null,
		new_user: null,
	};
	for (var key in get_dict) {
		const jsonValue = localStorage.getItem(key);
		try {
			get_dict[key] = jsonValue != null ? JSON.parse(jsonValue) : null;
		} catch {
			get_dict[key] = null;
		}
	}
	return get_dict;
};
