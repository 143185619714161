import Delete from "@mui/icons-material/Delete";
import MoreVert from "@mui/icons-material/MoreVert";
import { Button, Checkbox, Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import Popover from "@mui/material/Popover";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import ConfirmationContext from "../../../contexts/ConfirmationContext";
import "./style.scss";

const Table = ({
	data,
	used_columns,
	selectable,
	new_item = null,
	actions = null,
	contained = false,
	title = "",
	new_text = "",
}) => {
	const [checked, setChecked] = useState([]);
	const { open_confirm, setToast } = useContext(ConfirmationContext);
	const [usedColumns, setUsedColumns] = useState(used_columns);

	const check = (id) => {
		if (id == "all") {
			if (checked.length == data.length) {
				setChecked([]);
			} else {
				setChecked(data.map((elem) => elem.id));
			}
		} else {
			if (checked.includes(id)) {
				const new_checked = [...checked];
				new_checked.splice(new_checked.indexOf(id), 1);
				setChecked(new_checked);
			} else {
				setChecked([...checked, id]);
			}
		}
	};

	const RenderTableHeader = () => {
		const [filterAnchor, setFilterAnchor] = useState(null);
		const [columnAnchor, setColumnAnchor] = useState(null);

		const [shownColumns, setShownColumns] = useState(usedColumns.map((elem) => elem.column));

		const filter_open = Boolean(filterAnchor);
		const column_open = Boolean(columnAnchor);

		const toggle_checked = (column) => {
			if (shownColumns.includes(column)) {
				const tmp = [...shownColumns];
				tmp.splice(tmp.indexOf(column), 1);
				setShownColumns(tmp);
			} else {
				setShownColumns([...shownColumns, column]);
			}
		};

		const cancel = () => {
			if (column_open) {
				setColumnAnchor(null);
			}
		};

		const submit = () => {
			const used = [];
			for (let index = 0; index < used_columns.length; index++) {
				const element = used_columns[index];
				if (shownColumns.includes(element.column)) used.push(element);
			}
			setUsedColumns(used);
		};

		const handleClick = (event, type) => {
			if (type == "filter") setFilterAnchor(event.currentTarget);
			else setColumnAnchor(event.currentTarget);
		};

		const handleClose = (type) => {
			if (type == "filter") setFilterAnchor(null);
			else setColumnAnchor(null);
		};

		return (
			<div className={`table-header${checked.length ? " checked" : ""}`}>
				<Popover
					open={filter_open}
					anchorEl={filterAnchor}
					onClose={() => handleClose("filter")}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
				>
					<div className="popover-div">
						<p>Filters comming soon</p>
						<div className="popover-footer">
							<Button onClick={cancel} variant="outlined">
								Cancel
							</Button>
							<Button onClick={submit} variant="contained">
								Apply
							</Button>
						</div>
					</div>
				</Popover>
				<Popover
					open={column_open}
					anchorEl={columnAnchor}
					onClose={() => handleClose("columns")}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
				>
					<div className="popover-div">
						{used_columns.map((elem, id) => (
							<div key={id} onClick={() => toggle_checked(elem.column)}>
								<Checkbox
									size="small"
									checked={shownColumns.includes(elem.column)}
								/>
								<span>{elem.str_column}</span>
							</div>
						))}
						<div className="popover-footer">
							<Button onClick={cancel} variant="outlined">
								Cancel
							</Button>
							<Button
								disabled={
									usedColumns
										.map((elem) => elem.column)
										.sort()
										.join("|") == shownColumns.sort().join("|")
								}
								onClick={submit}
								variant="contained"
							>
								Apply
							</Button>
						</div>
					</div>
				</Popover>
				<div className="center">
					<div className="table-header-title-icons">
						<h1>{checked.length ? `${checked.length} selected` : title}</h1>
						{checked.length ? null : (
							<>
								<FilterListIcon onClick={(e) => handleClick(e, "filter")} />
								<ViewWeekIcon onClick={(e) => handleClick(e, "columns")} />
							</>
						)}
					</div>
					<div>
						{checked.length ? (
							<RenderActions />
						) : new_item ? (
							<Button onClick={new_item}>{new_text}</Button>
						) : null}
					</div>
				</div>
			</div>
		);
	};

	const RenderTableData = ({ value, type, data, item = null }) => {
		if (type == "str") {
			if (data.max_len && value.length > data.max_len)
				return value.substring(0, data.max_len) + "...";
			return value + (data.add_after ? data.add_after : "");
		} else if (type == "date") {
			var opt_weekday = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
			return (
				new Date(value).toLocaleDateString(`fr-FR`, opt_weekday) +
				(data.add_after ? data.add_after : "")
			);
		} else if (type == "date-full") {
			var opt_weekday = {
				weekday: "short",
				year: "numeric",
				month: "short",
				day: "numeric",
				hour: "numeric",
				minute: "numeric",
				second: "numeric",
			};
			return (
				new Date(value).toLocaleDateString(`fr-FR`, opt_weekday) +
				(data.add_after ? data.add_after : "")
			);
		} else if (type == "card") {
			return (
				<div
					className="table-elem-card"
					style={{ backgroundColor: data.card_value[value]?.color }}
				>
					{data.card_value[value]?.title} {data.add_after ? data.add_after : ""}
				</div>
			);
		} else if (type == "card-device") {
			const data_text = value
				? value
				: item.implementation == ""
				? null
				: item.implementation;
			const title = data.card_value[data_text]?.title
				? data.card_value[data_text]?.title
				: "Deleted device";
			const color = data.card_value[value]?.color
				? data.card_value[value]?.color
				: "var(--error)";
			return (
				<div className="table-elem-card" style={{ backgroundColor: color }}>
					{title} {data.add_after ? data.add_after : ""}
				</div>
			);
		}
	};

	const RenderActions = () => {
		const [anchorEl, setAnchorEl] = React.useState(null);
		const open = Boolean(anchorEl);
		const handleClick = (event) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const handleAction = async (action) => {
			await action(checked);
			setChecked([]);
		};

		if (!actions) return null;
		return (
			<div className="action-buttons">
				{actions.delete ? (
					<Tooltip title={actions.delete.label}>
						<Delete
							onClick={() =>
								open_confirm(
									actions.delete.label,
									"The deletion of the selection is permanent",
									"",
									() => handleAction(actions.delete.action)
								)
							}
						/>
					</Tooltip>
				) : null}
				{actions.more ? (
					<Tooltip title="More options">
						<MoreVert onClick={handleClick} />
					</Tooltip>
				) : null}
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
				>
					{actions.more?.map((elem, id) => (
						<div key={id}>
							<MenuItem
								key={id}
								onClick={() => {
									handleClose();
									handleAction(elem.action);
								}}
							>
								{elem.label}
							</MenuItem>
							{elem.need_border ? <Divider /> : null}
						</div>
					))}
				</Menu>
			</div>
		);
	};

	return (
		<div className={`table-container ${contained ? "contained" : ""}`}>
			<RenderTableHeader />
			<div className="table-div">
				<table className="main-table">
					<thead>
						<tr>
							{selectable ? (
								<th onClick={() => check("all")}>
									<Checkbox
										checked={
											checked.length == data.length && checked.length != 0
										}
										className="checkbox"
										size="small"
									/>
								</th>
							) : null}
							{usedColumns.map((elem, id) => (
								<th key={id}>{elem.str_column.toUpperCase()}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data.map((column, id) => (
							<tr key={id}>
								{selectable ? (
									<td>
										<Checkbox
											checked={checked.includes(column.id)}
											onClick={() => check(column.id)}
											className="checkbox"
											size="small"
										/>
									</td>
								) : null}
								{usedColumns.map((row, row_id) => (
									<td
										onClick={() => (selectable ? check(column.id) : null)}
										key={row_id}
									>
										<RenderTableData
											value={column[row.column]}
											type={row.var_type}
											data={row}
											item={column}
										/>
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default Table;
