import axios from "axios";
import { baseURL } from "../constants/constants";

const instance = axios.create({
	baseURL: `${baseURL}`,
	headers: {
		"Content-Type": "application/json",
		Authorization: "Bearer hello",
	},
	timeout: 5000,
});

export const set_instance_token = (token) => {
	instance.defaults.headers.authorization = `Bearer ${token}`;
};

export const unset_instance_token = () => {
	instance.defaults.headers.authorization = "";
};

export default instance;
