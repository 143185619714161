import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../services/axios";

export const fetchMessages = createAsyncThunk(
	"messages/fetchMessages",
	async ({ account_id, filters = "" }) => {
		if (!account_id) return null;
		const response = await axios.get(`/api/accounts/${account_id}/text-messages${filters}`);
		return response.data;
	}
);

export const createMessages = createAsyncThunk(
	"messages/createMessages",
	async ({ account_id, message, phone_number }) => {
		const response = await axios.post(`/api/text-messages/${account_id}`, {
			message: message,
			phone_number: phone_number,
		});
		return response.data;
	}
);

export const deleteMessage = createAsyncThunk("messages/deleteMessages", async ({ message_id }) => {
	const response = await axios.delete(`/api/text-messages-dashboard/${message_id}`);
	return message_id;
});

export const updateMessage = createAsyncThunk(
	"messages/updateMessages",
	async ({ message_id, message_updated }) => {
		const response = await axios.put(
			`/api/text-messages-dashboard/${message_id}`,
			message_updated
		);
		return response.data;
	}
);

export const resendMessage = createAsyncThunk("messages/resendMessage", async (id) => {
	const response = await axios.put(`/api/text-messages-dashboard/resend/${id}`);
	return response.data;
});

function formatTime(seconds) {
	if (seconds == -1) {
		return "";
	}
	if (seconds < 60) {
		// less than 1 minute
		return seconds + " sec";
	} else if (seconds < 3600) {
		// less than 1 hour
		var minutes = Math.floor(seconds / 60);
		var sec = seconds % 60;
		return minutes + " min " + parseInt(sec) + " sec";
	} else {
		// 1 hour or more
		var hours = Math.floor(seconds / 3600);
		var minutes = Math.floor((seconds % 3600) / 60);
		var sec = seconds % 60;
		return hours + " h " + minutes + " min " + parseInt(sec) + " sec";
	}
}

const messagesSlice = createSlice({
	name: "messages",
	initialState: null,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchMessages.fulfilled, (state, { payload }) => {
			payload.map((elem) => {
				elem.sent_in = formatTime(elem.sent_in);
				elem.delivery_in = formatTime(elem.delivery_in);
				return elem;
			});
			return payload;
		});
		builder.addCase(createMessages.fulfilled, (state, { payload }) => {
			payload.sent_in = formatTime(payload.sent_in);
			payload.delivery_in = formatTime(payload.delivery_in);
			return [payload, ...state];
		});
		builder.addCase(deleteMessage.fulfilled, (state, { payload }) => {
			const old = state.filter((elem) => elem.id == payload);
			if (old.length == 0) return state;
			state.splice(state.indexOf(old[0]), 1);
			return state;
		});
		builder.addCase(updateMessage.fulfilled, (state, { payload }) => {
			const old = state.filter((elem) => elem.id == payload.id);
			payload.sent_in = formatTime(payload.sent_in);
			payload.delivery_in = formatTime(payload.delivery_in);
			if (old.length == 0) return state;
			state.splice(state.indexOf(old[0]), 1, payload);
			return state;
		});
		builder.addCase(resendMessage.fulfilled, (state, { payload }) => {
			const old = state.filter((elem) => elem.id == payload.id);
			if (old.length == 0) return state;
			state.splice(state.indexOf(old[0]), 1, payload);
			return state;
		});
	},
});

export const messagesMethods = {
	fetchMessages,
	createMessages,
	deleteMessage,
	updateMessage,
	resendMessage,
};

export default messagesSlice;
