import { TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import CreateBox from "../../components/main/create_box/CreateBox";
import Loading from "../../components/main/loading/Loading";
import Paginator from "../../components/main/paginator/Paginator";
import Table from "../../components/main/table/Table";
import TableHeader from "../../components/main/table_header/TableHeader";
import { TOAST_LVL } from "../../constants/constants";
import AuthContext from "../../contexts/AuthContext";
import ConfirmationContext from "../../contexts/ConfirmationContext";
import { set_doc_title } from "../../functions/main";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import "./style.scss";

const AllMessages = ({
	messages,
	fetchMessages,
	devices,
	createMessages,
	deleteMessage,
	updateMessage,
	resendMessage,
	createBlackListed,
	black_list,
}) => {
	const new_message_phone = useRef("");
	const new_message_text = useRef("");
	const { user } = useContext(AuthContext);
	const { setToast } = useContext(ConfirmationContext);
	const [newMessage, setNewMessage] = useState(false);

	useEffect(() => {
		set_doc_title("Messages", user.account.name);
		let url_params = window.location.href.substring(window.location.href.indexOf("?"));
		if (window.location.href.indexOf("?") == -1) url_params = "";
		fetchMessages({ account_id: user.account.id, filters: url_params });
	}, []);

	if (!messages) {
		return <Loading />;
	}

	const front_devices = {
		null: { title: "unset", color: "var(--divider)" },
		TW: { title: "Twilio", color: "var(--divider)" },
	};
	devices.map((elem, id) => {
		front_devices[elem.id] = { title: elem.name, color: elem.color };
	});

	const send_message = () => {
		if (new_message_phone.current != "" && new_message_text.current != "") {
			createMessages({
				account_id: user.account.id,
				message: new_message_text.current,
				phone_number: new_message_phone.current,
			});
			new_message_text.current = "";
			new_message_phone.current = "";
		}
		setNewMessage(false);
	};

	const delete_bulk = async (ids) => {
		ids.forEach(async (id) => {
			await deleteMessage({ message_id: id });
		});
		setToast.current(
			TOAST_LVL.SUCCESS,
			"Deletion completed",
			`${ids.length} text message${
				ids.length > 1 ? "s have" : " has"
			} been successfully deleted`
		);
	};

	const update_bulk = (ids, update) => {
		if (update == "sended") {
			ids.forEach(async (id) => {
				let old = messages.filter((elem) => elem.id == id);
				if (!old.length) return;
				old = old[0];
				const updated = {
					message: old.message,
					phone_number: old.phone_number,
					text_status: "1",
					status_code: -2,
				};
				await updateMessage({ message_id: id, message_updated: updated });
			});
		}
		setToast.current(
			TOAST_LVL.SUCCESS,
			"Update completed",
			`${ids.length} text message${
				ids.length > 1 ? "s have" : " has"
			} been successfully updated`
		);
	};

	const resend_bulk = (ids) => {
		ids.forEach(async (id) => {
			let old = messages.filter((elem) => elem.id == id);
			if (!old.length) return;
			old = old[0];
			await resendMessage(id);
		});
		setToast.current(
			TOAST_LVL.SUCCESS,
			"Resend completed",
			`${ids.length} text message${ids.length > 1 ? "s have" : " has"} been set for resend`
		);
	};

	const create_black_list_bulk = (ids) => {
		const l = [];
		ids.forEach(async (id) => {
			let msg = messages.filter((elem) => elem.id == id);
			if (!msg.length) return;
			msg = msg[0];
			let exist = black_list.filter((elem) => elem.phone_number == msg.phone_number).length;
			if (!exist) {
				l.push(id);
				createBlackListed({
					account_id: user.account.id,
					phone_number: msg.phone_number,
					reason: "0",
				});
			}
		});
		setToast.current(
			TOAST_LVL.SUCCESS,
			"Update completed",
			`${l.length} phone number${
				l.length > 1 ? "s have" : " has"
			} been successfully added to blacklist`
		);
	};

	return (
		<div className="table-view">
			<CreateBox
				title={"New message"}
				visible={newMessage}
				cancel={() => setNewMessage(false)}
				submit={() => send_message()}
				submit_text="Create"
				submitable={true}
			>
				<div className="create-box-body">
					<TextField
						size="small"
						type="tel"
						defaultValue={new_message_phone.current}
						onChange={(e) => (new_message_phone.current = e.target.value)}
						fullWidth
						className="value-input"
						id="outlined-basic"
						label="Phone number"
						variant="outlined"
					/>
					<TextField
						size="small"
						type="text"
						defaultValue={new_message_text.current}
						onChange={(e) => (new_message_text.current = e.target.value)}
						fullWidth
						className="value-input"
						id="outlined-basic"
						label="Message"
						variant="outlined"
					/>
				</div>
			</CreateBox>
			<Table
				new_item={() => setNewMessage(true)}
				new_text="Create message"
				selectable={true}
				title={"Text messages"}
				data={messages}
				actions={{
					delete: {
						label: "Delete selection",
						ok_text_title: "Deletion ok",
						ok_text_messages: "deleted successfully",
						action: delete_bulk,
					},
					more: [
						{
							label: "Set selection as sended",
							ok_text_title: "Update ok",
							ok_text_messages: "set as sended",
							action: (e) => update_bulk(e, "sended"),
						},
						{
							label: "Resend selection",
							ok_text_title: "Update ok",
							ok_text_messages: "set for resend",
							action: (e) => resend_bulk(e),
						},
						{
							label: "Add numbers to blacklist",
							ok_text_title: "Update ok",
							ok_text_messages: "added to blacklist",
							action: create_black_list_bulk,
							need_border: true,
						},
						{
							label: "Download in xls format",
							action: () => console.log("download"),
						},
					],
				}}
				used_columns={[
					{
						column: "id",
						str_column: "id",
						var_type: "str",
					},
					{
						column: "text_status",
						str_column: "status",
						var_type: "card",
						card_value: {
							0: { title: "Pending", color: "var(--pending)" },
							1: { title: "Sent", color: "var(--sent)" },
							2: { title: "Delivery", color: "var(--delivery)" },
							3: { title: "Error", color: "var(--msg-error)" },
							4: { title: "Number on blacklist", color: "var(--msg-error)" },
							5: { title: "Invalid number", color: "var(--msg-error)" },
							8: { title: "Pending from implementation", color: "var(--pending)" },
							9: { title: "Unset", color: "var(--pending)" },
						},
					},
					{
						column: "device",
						str_column: "device",
						var_type: "card-device",
						card_value: front_devices,
					},
					{
						column: "message",
						str_column: "message",
						var_type: "str",
						max_len: 60,
					},
					{
						column: "phone_number",
						str_column: "phone number",
						var_type: "str",
					},
					{
						column: "created_at",
						str_column: "created at",
						var_type: "date",
					},
					{
						column: "sent_in",
						str_column: "Sent in",
						var_type: "str",
					},
					{
						column: "delivery_in",
						str_column: "Delivert in",
						var_type: "str",
					},
				]}
			/>
			<Paginator count={messages.length} />
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(AllMessages);
