import { Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultView from "../../../components/account_settings/default_view/DefaultView";
import AuthContext from "../../../contexts/AuthContext";
import "./style.scss";
import DownloadIcon from "@mui/icons-material/Download";
import { json, useNavigate, useParams } from "react-router-dom";
import axios from "../../../services/axios";
import { COUNTRIES, paymentURL, TOAST_LVL } from "../../../constants/constants";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ConfirmationContext from "../../../contexts/ConfirmationContext";
import BillingInformations from "../../../components/account_settings/billing_informations/BillingInformations";
import BillingTable from "../../../components/account_settings/billing_table/BillingTable";

export const sms_dispatcher_pricing_plans = {
	0: {
		name: "Testing plan",
		price: 0,
		description: "Perfect plan to setup and test",
	},
	1: {
		name: "Basic plan",
		price: 13,
		description: "Our most popular plan for small business",
	},
	2: {
		name: "Plus plan",
		price: 26,
		description: "Recommended plan",
	},
	3: {
		name: "Pro plan",
		price: 46,
		description: "Our most popular plan for small business",
	},
};

const Billing = () => {
	const { t } = useTranslation("account_settings");
	const { user, accounts } = useContext(AuthContext);
	const { setToast } = useContext(ConfirmationContext);
	const navigate = useNavigate();
	const [invoices, setInvoices] = useState([]);
	const { account_id } = useParams();
	const used_account = account_id ? account_id : user.account.id;
	const [usedAccount, setUsedAccount] = useState({ ...user.account });

	const setup_invoices = async () => {
		const resp = await axios
			.get(`/api/payments/${used_account}`)
			.then((e) => e.data)
			.catch((e) => null);
		if (resp) {
			setInvoices(resp);
		}
		const ret = await axios
			.get(`/api/accounts/${used_account}`)
			.then((response) => {
				return response.data;
			})
			.catch((e) => {
				console.log(e);
				return null;
			});
		if (ret) {
			setUsedAccount(ret);
		}
	};

	useEffect(() => {
		if (!accounts.current.filter((elem) => elem.id == used_account).length) {
			navigate("/account-settings/billing");
			return;
		}
		if (window.location.href.includes("success")) {
			navigate("/account-settings/billing");
			setToast.current(TOAST_LVL.SUCCESS, "Payment successfull", "The invoice is paid");
			setup_invoices();
		} else if (window.location.href.includes("cancel")) {
			navigate("/account-settings/billing");
			setToast.current(TOAST_LVL.ERROR, "Payment canceled", "The payment has been canceled");
			setup_invoices();
		} else {
			setup_invoices();
		}
	}, []);

	return (
		<DefaultView hide_navbar={account_id != undefined}>
			<div className="settings-content" id="billing-view">
				<div className="settings-center">
					<div className="setting-block-title">
						<h1>{t("Plans and billing", { account_name: usedAccount.name })}</h1>
						<p>
							{t("Plans and billing description", { account_name: usedAccount.name })}
						</p>
					</div>
					<BillingInformations account={usedAccount} />
					{account_id ? null : (
						<div className="billing-header">
							<div className="current-billing-plan">
								<div className="billing-plan-header">
									<div className="billing-plan-title">
										<span>
											{
												sms_dispatcher_pricing_plans[
													usedAccount.current_plan
												].name
											}{" "}
											<span className="active-plan">Active</span>
										</span>
										<p>
											{
												sms_dispatcher_pricing_plans[
													usedAccount.current_plan
												].description
											}
										</p>
									</div>
									<div className="billing-plan-price">
										<span>
											<span className="big-price">
												{usedAccount.current_plan == "0"
													? "Free"
													: `$${
															sms_dispatcher_pricing_plans[
																usedAccount.current_plan
															].price
													  }`}
											</span>
											{usedAccount.current_plan == "0" ? "" : "per month"}
										</span>
									</div>
								</div>

								<div className="billing-plan-footer">
									<Button
										onClick={() => navigate("/pricing-plan")}
										variant="outlined"
									>
										Upgrade plan
									</Button>
								</div>
							</div>
							<div className="current-billing-plan">
								<div className="billing-plan-header">
									<div className="billing-plan-title">
										<span>
											{
												sms_dispatcher_pricing_plans[
													usedAccount.next_month_plan
												].name
											}{" "}
											<span className="next active-plan">Next month</span>
										</span>
										<p>
											{
												sms_dispatcher_pricing_plans[
													usedAccount.next_month_plan
												].description
											}
										</p>
									</div>
									<div className="billing-plan-price">
										<span>
											<span className="big-price">
												{usedAccount.next_month_plan == "0"
													? "Free"
													: `$${
															sms_dispatcher_pricing_plans[
																usedAccount.next_month_plan
															].price
													  }`}
											</span>
											{usedAccount.next_month_plan == "0" ? "" : "per month"}
										</span>
									</div>
								</div>

								<div className="billing-plan-footer">
									<Button
										onClick={() => navigate("/next-pricing-plan")}
										variant="outlined"
									>
										Change
									</Button>
								</div>
							</div>
						</div>
					)}
					<BillingTable invoices={invoices} title={"Invoices history"} />
				</div>
			</div>
		</DefaultView>
	);
};

export default Billing;
