import { configureStore } from "@reduxjs/toolkit";
import blackListSlice from "./slices/black_list";
import dashboardSlice from "./slices/dashboard";
import devicesSlice from "./slices/devices";
import messagesSlice from "./slices/messages";
import scheduledMessagesSlice from "./slices/scheduled_messages";

export const store = configureStore({
	reducer: {
		messages: messagesSlice.reducer,
		black_list: blackListSlice.reducer,
		scheduled_messages: scheduledMessagesSlice.reducer,
		devices: devicesSlice.reducer,
		dashboard: dashboardSlice.reducer,
	},
});
