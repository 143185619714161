import { Button, Drawer, TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AuthContext from "../../../contexts/AuthContext";
import "./style.scss";
import { makeStyles, withStyles } from "@mui/styles";
import { getSpecificData } from "../../../functions/store_data";

const Navbar = () => {
	const location = useLocation();
	const { select_account, user, get_accounts, create_account, logout, take_down } =
		useContext(AuthContext);
	const [selectOpen, setSelectOpen] = useState(user.account.id == undefined);
	const [selectedAccount, setSelectedAccount] = useState("");
	const [createAccount, setCreateAccount] = useState(false);
	const [drawerOpen, setDrawerOpen] = useState(false);
	let new_account_name = useRef("");

	let all_accounts = get_accounts();

	useEffect(() => {
		setSelectOpen(user.account.id == undefined);
	}, [user]);

	const toggleDrawer = (open) => (event) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}

		setDrawerOpen(open);
	};

	const SelectAccount = () => {
		const switch_account = () => {
			if (selectedAccount != "0") {
				select_account(selectedAccount);
				setSelectOpen(false);
			} else {
				if (!createAccount) {
					setCreateAccount(true);
				} else {
					create_account(new_account_name.current).then(
						(e) => (all_accounts = get_accounts())
					);
					setCreateAccount(false);
					new_account_name.current = "";
					setSelectOpen(false);
				}
			}
		};

		if (!selectOpen) return null;
		return (
			<div className="select-account-view">
				<div className="select-account-center">
					<div className="select-account-header">
						<h3>Select account</h3>
					</div>
					<div className="select-account-body">
						{createAccount ? (
							<div className="create-account">
								<p>Choose a name for the new account</p>
								<TextField
									defaultValue={new_account_name.current}
									onChange={(e) => (new_account_name.current = e.target.value)}
									fullWidth
									className="value-input"
									id="outlined-basic"
									label={"Account name"}
									variant="outlined"
								/>
							</div>
						) : (
							<>
								{all_accounts.map((elem, id) => (
									<div
										onClick={() => setSelectedAccount(elem.id)}
										key={id}
										className={`account-elem${
											selectedAccount == elem.id ? " selected" : ""
										}${elem.id == "0" ? " outlined" : ""}`}
									>
										<i
											className={`fas ${
												elem.id == "0" ? "fa-plus" : "fa-briefcase"
											}`}
										></i>
										<span>{elem.name}</span>
									</div>
								))}
								<div
									onClick={() => setSelectedAccount("0")}
									className={`account-elem outlined ${
										selectedAccount == "0" ? "selected" : ""
									}`}
								>
									<i className={`fas fa-plus`}></i>
									<span>Create new account</span>
								</div>
							</>
						)}
					</div>
					<div className="select-account-footer">
						<Button
							disabled={!user.account.id}
							onClick={() =>
								createAccount ? setCreateAccount(false) : setSelectOpen(false)
							}
							variant="outlined"
						>
							Cancel
						</Button>
						<Button
							disabled={selectedAccount == ""}
							onClick={() => switch_account()}
							variant="contained"
						>
							{selectedAccount == "0" ? "Create" : "Select"}
						</Button>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			<header>
				<div onClick={() => setSelectOpen(true)} className="current-account">
					<i className="fas fa-briefcase"></i>
					<span>{user.account.name}</span>
				</div>
				<ul>
					<li
						className={location.pathname === "/settings/profile" ? "active hov" : "hov"}
					>
						<Link aria-label="User Profile" to="/settings/profile">
							<i className="fas fa-user"></i>
						</Link>
					</li>
					<li
						className={`large ${
							location.pathname.includes("/settings") &&
							!location.pathname.includes("profile")
								? "active hov"
								: "hov"
						}`}
					>
						<Link aria-label="Settings" to="/settings">
							<i className="fas fa-cog"></i>
						</Link>
					</li>
					<li
						className={`small ${
							location.pathname.includes("/settings") &&
							!location.pathname.includes("profile")
								? "active hov"
								: "hov"
						}`}
					>
						<Link aria-label="Settings" to="/settings/navigation">
							<i className="fas fa-cog"></i>
						</Link>
					</li>
					{getSpecificData("old-token") ? (
						<li>
							<Button
								onClick={take_down}
								color="error"
								variant="outlined"
								size="small"
							>
								Leave take over
							</Button>
						</li>
					) : null}
				</ul>
				<div className="drawer-button">
					<DragHandleIcon sx={{ fontSize: 32 }} onClick={toggleDrawer(true)} />
					<Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
						<div className="drawer-content">
							<div
								onClick={() => setSelectOpen(true)}
								className="current-account-block"
							>
								<div className="current-account-picture">
									<i className="fas fa-briefcase"></i>
								</div>
								<span>{user.account.name}</span>
							</div>
							<ul onClick={() => setDrawerOpen(false)}>
								<li
									className={
										location.pathname === "/settings/profile"
											? "active hov"
											: "hov"
									}
								>
									<Link to="/settings/profile">
										<div className="navigation_elem">
											<i className="fas fa-user"></i>
											<span>Profile</span>
										</div>
									</Link>
								</li>
								<li
									className={
										location.pathname.includes("/settings") &&
										!location.pathname.includes("profile")
											? "active hov"
											: "hov"
									}
								>
									<Link to="/settings/navigation">
										<div className="navigation_elem">
											<i className="fas fa-cog"></i>
											<span>Settings</span>
										</div>
									</Link>
								</li>
								<li>
									<div className="separator">
										<h2 className="main">Current account</h2>
									</div>
								</li>
								<li className={location.pathname === "/" ? "active hov" : "hov"}>
									<Link to="/">
										<div className="navigation_elem">
											<i className="fas fa-columns"></i>
											<span>Dashboard</span>
										</div>
									</Link>
								</li>
								<li
									className={
										location.pathname === "/devices" ? "active hov" : "hov"
									}
								>
									<Link to="/devices">
										<div className="navigation_elem">
											<i className="fas fa-mobile"></i>
											<span>Devices</span>
										</div>
									</Link>
								</li>
								<li>
									<div className="separator">
										<h2 className="separator_title">Messages</h2>
									</div>
								</li>
								<li
									className={
										location.pathname === "/messages" ? "active hov" : "hov"
									}
								>
									<Link to="/messages">
										<div className="navigation_elem">
											<i className="fas fa-comment"></i>
											<span>Messages</span>
										</div>
									</Link>
								</li>
								<li
									className={
										location.pathname === "/scheduled-messages"
											? "active hov"
											: "hov"
									}
								>
									<Link to="/scheduled-messages">
										<div className="navigation_elem">
											<i className="fas fa-calendar-alt"></i>
											<span>Scheduled Messages</span>
										</div>
									</Link>
								</li>
								<li
									className={
										location.pathname === "/black-list-numbers"
											? "active hov"
											: "hov"
									}
								>
									<Link to="/black-list-numbers">
										<div className="navigation_elem">
											<i className="fas fa-bug"></i>
											<span>Blacklisted Numbers</span>
										</div>
									</Link>
								</li>
								<li>
									<div className="separator">
										<h2 className="separator_title">Account</h2>
									</div>
								</li>
								<li
									className={
										location.pathname.includes("/account-settings") &&
										location.pathname !== "/account-settings/developers"
											? "active hov"
											: "hov"
									}
								>
									<Link to="/account-settings/navigation">
										<div className="navigation_elem">
											<i className="fas fa-cog"></i>
											<span>Account settings</span>
										</div>
									</Link>
								</li>
								<li
									className={
										location.pathname === "/account-settings/developers"
											? "active hov"
											: "hov"
									}
								>
									<Link to="/account-settings/developers">
										<div className="navigation_elem">
											<i className="fas fa-bug"></i>
											<p>Developers settings</p>
										</div>
									</Link>
								</li>
								<li>
									<div className="separator">
										<h2 className="separator_title">Disconnect</h2>
									</div>
								</li>
								<li
									className={
										location.pathname === "/logout" ? "active hov" : "hov"
									}
								>
									<Link to="/" onClick={logout}>
										<div className="navigation_elem">
											<i className="fas fa-sign-out-alt"></i>
											<span>Logout</span>
										</div>
									</Link>
								</li>
							</ul>
						</div>
					</Drawer>
				</div>
			</header>
			<SelectAccount />
		</>
	);
};

export default Navbar;
