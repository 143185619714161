import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DevicesTable from "../../../components/devices/DevicesTable/DevicesTable";
import TableHeader from "../../../components/main/table_header/TableHeader";
import PairDevice from "../../../components/pair_device/PairDevice";
import AuthContext from "../../../contexts/AuthContext";
import SocketContext from "../../../contexts/SocketContext";
import { set_doc_title } from "../../../functions/main";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import "./style.scss";

const Devices = ({ devices }) => {
	const [pairDevice, setPairDevice] = useState(false);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		set_doc_title("Devices", user.account.name);
	}, []);

	return (
		<div className="table-view devices">
			<PairDevice close={() => setPairDevice(false)} active={pairDevice} />
			<TableHeader
				title={"Devices"}
				new_text="Pair device"
				new_action={() => setPairDevice(true)}
			/>
			<DevicesTable devices={devices} />
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
