import { Checkbox } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultView from "../../../components/account_settings/default_view/DefaultView";
import AuthContext from "../../../contexts/AuthContext";

const NotificationsSettings = () => {
	const { t, i18n } = useTranslation("account_settings");
	const { user } = useContext(AuthContext);

	const [notifications, setNotifications] = useState({
		connection: false,
		disconnected_device: false,
		no_device: false,
		new_invoice: false,
	});

	const save = async () => {};

	const reset = () => {
		setNotifications({
			connection: false,
			disconnected_device: false,
			no_device: false,
			new_invoice: false,
		});
	};

	const valid = () => {
		return false;
	};

	return (
		<DefaultView save={save} reset={reset} is_valid={valid}>
			<div className="settings-content">
				<div className="settings-center">
					<div className="setting-block-title">
						<h1>{t("Notifications", { account_name: user.account.name })}</h1>
						<p>{t("Notifications description", { account_name: user.account.name })}</p>
					</div>
					<div className="setting-block">
						<div className="setting-block-description">
							<h2>{t("notification-connection")}</h2>
							<p>{t("notification-connection-description")}</p>
						</div>
						<div className="setting-block-content">
							<Checkbox
								onChange={() =>
									setNotifications({
										...notifications,
										connection: !notifications.connection,
									})
								}
								checked={notifications.connection}
								size="large"
							/>
						</div>
					</div>
					<div className="setting-block">
						<div className="setting-block-description">
							<h2>{t("notification-disconnected")}</h2>
							<p>{t("notification-disconnected-description")}</p>
						</div>
						<div className="setting-block-content">
							<Checkbox
								onChange={() =>
									setNotifications({
										...notifications,
										disconnected_device: !notifications.disconnected_device,
									})
								}
								checked={notifications.disconnected_device}
								size="large"
							/>
						</div>
					</div>
					<div className="setting-block">
						<div className="setting-block-description">
							<h2>{t("notification-no-device")}</h2>
							<p>{t("notification-no-device-description")}</p>
						</div>
						<div className="setting-block-content">
							<Checkbox
								onChange={() =>
									setNotifications({
										...notifications,
										no_device: !notifications.no_device,
									})
								}
								checked={notifications.no_device}
								size="large"
							/>
						</div>
					</div>
					<div className="setting-block">
						<div className="setting-block-description">
							<h2>{t("notification-invoice")}</h2>
							<p>{t("notification-invoice-description")}</p>
						</div>
						<div className="setting-block-content">
							<Checkbox
								onChange={() =>
									setNotifications({
										...notifications,
										new_invoice: !notifications.new_invoice,
									})
								}
								checked={notifications.new_invoice}
								size="large"
							/>
						</div>
					</div>
				</div>
			</div>
		</DefaultView>
	);
};

export default NotificationsSettings;
