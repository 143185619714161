import { LoadingButton } from "@mui/lab";
import { Button, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import axios from "../../../../services/axios";
import AuthContext from "../../../../contexts/AuthContext";
import ConfirmationContext from "../../../../contexts/ConfirmationContext";
import { TOAST_LVL } from "../../../../constants/constants";

function validateEmail(email) {
	// Regular expression pattern for email validation
	const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	// Check if the email matches the pattern
	if (emailPattern.test(email)) {
		return true; // Valid email
	} else {
		return false; // Invalid email
	}
}

const NewMember = ({ add_member, close, active }) => {
	const { user } = useContext(AuthContext);
	const [userEmail, setUserEmail] = useState("");
	const { open_confirm, setToast } = useContext(ConfirmationContext);
	const [loading, setLoading] = useState(false);

	const add_user = async () => {
		setLoading(true);
		const ret = await axios
			.post(`/api/accounts/${user.account.id}/users`, {
				email: userEmail,
			})
			.then((e) => e.data)
			.catch((e) => null);
		if (ret) {
			setLoading(false);
			setUserEmail("");
			add_member();
			close();
		} else {
			setLoading(false);
			setToast.current(TOAST_LVL.ERROR, "Not found", "This email is not valid");
		}
	};

	if (!active) {
		return null;
	}

	return (
		<div id="new-key-div" className="pair-device">
			<div className="pair-device-center">
				<div className="pair-device-header">
					<h3>New member</h3>
				</div>
				<div className="pair-device-body">
					<p>
						Enter the email of the new user. This is the email the user use to login to
						his account.
					</p>
					<TextField
						value={userEmail}
						onChange={(e) => setUserEmail(e.target.value)}
						size="small"
						fullWidth
						label="Member email"
					/>
				</div>
				<div className="pair-device-footer">
					<Button onClick={close} variant="outlined">
						Close
					</Button>
					<LoadingButton
						loading={loading}
						disabled={!validateEmail(userEmail)}
						onClick={() => add_user()}
						variant="contained"
					>
						Add member
					</LoadingButton>
				</div>
			</div>
		</div>
	);
};

export default NewMember;
