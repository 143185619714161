export const generate_dashboard_data = (dashboard_data, devices) => {
	dashboard_data.sent_error_chart = [];
	dashboard_data.sent_delivery_time_chart = [];
	const dashboard_data_list = [];

	Object.keys(dashboard_data.count_char).map((elem, id) => {
		dashboard_data_list.push(dashboard_data.count_char[elem]);
	});

	dashboard_data.count_char = dashboard_data_list.sort((e1, e2) => e1.order - e2.order);
	var opt_weekday = { month: "short", day: "numeric" };

	for (let index = 0; index < dashboard_data.count_char.length; index++) {
		const elem = dashboard_data.count_char[index];
		dashboard_data.sent_error_chart.push({
			name: new Date(elem.date).toLocaleDateString(`fr-FR`, opt_weekday),
			sent: elem.sent_count,
			error: elem.error,
		});
	}

	for (let index = 0; index < dashboard_data.count_char.length; index++) {
		const elem = dashboard_data.count_char[index];
		if (elem.sent_count == 0) {
			dashboard_data.sent_delivery_time_chart.push({
				name: new Date(elem.date).toLocaleDateString(`fr-FR`, opt_weekday),
				sent: 0,
				delivery: 0,
			});
			continue;
		}
		dashboard_data.sent_delivery_time_chart.push({
			name: new Date(elem.date).toLocaleDateString(`fr-FR`, opt_weekday),
			sent:
				elem.send_time / elem.sent_count > 20
					? 20
					: (elem.send_time / elem.sent_count).toFixed(2),
			delivery:
				elem.delivery_time / elem.delivery_count > 20
					? 20
					: (elem.delivery_time / elem.delivery_count).toFixed(2),
		});
	}

	const dashboard_data_device_list = [
		{
			subject: "Sent count",
			fullMark: 0,
		},
		{
			subject: "Delivery count",
			fullMark: 0,
		},
		{
			subject: "Error count",
			fullMark: 0,
		},
	];

	devices.forEach((device) => {
		dashboard_data_device_list[0][device.id] = 0;
		dashboard_data_device_list[1][device.id] = 0;
		dashboard_data_device_list[2][device.id] = 0;
	});

	Object.keys(dashboard_data.devices_char).map((elem, id) => {
		const element = dashboard_data.devices_char[elem];
		const sel_dev = devices.filter((e) => e.id == elem);
		const elem_name = sel_dev.length ? elem : "deleted";
		dashboard_data_device_list[0][elem_name] = element.sent_count;
		if (dashboard_data_device_list[0].fullMark < element.sent_count)
			dashboard_data_device_list[0].fullMark = element.sent_count;
		dashboard_data_device_list[1][elem_name] = element.delivery_count;
		if (dashboard_data_device_list[1].fullMark < element.delivery_count)
			dashboard_data_device_list[1].fullMark = element.delivery_count;
		dashboard_data_device_list[2][elem_name] = element.error;
		if (dashboard_data_device_list[2].fullMark < element.error)
			dashboard_data_device_list[2].fullMark = element.error;
	});

	dashboard_data.dashboard_data_device_list = dashboard_data_device_list;

	dashboard_data.dashboard_send_circle = [
		{ name: "sent-less-4", value: dashboard_data.send_time.less_4_s },
		{ name: "sent-less-10", value: dashboard_data.send_time.less_10_s },
		{ name: "sent-less-120", value: dashboard_data.send_time.less_120_s },
		{ name: "sent-more-120", value: dashboard_data.send_time.more_120_s },
	];

	dashboard_data.dashboard_delivery_circle = [
		{ name: "delivery-less-4", value: dashboard_data.delivery_time.less_4_s },
		{ name: "delivery-less-10", value: dashboard_data.delivery_time.less_10_s },
		{ name: "delivery-less-120", value: dashboard_data.delivery_time.less_120_s },
		{ name: "delivery-more-120", value: dashboard_data.delivery_time.more_120_s },
	];

	return dashboard_data;
};
