import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../services/axios";

export const fetchBlackListed = createAsyncThunk(
	"blackList/fetchBlackListed",
	async (account_id) => {
		if (!account_id) return null;
		const response = await axios.get(`/api/text-messages/black-list/${account_id}`);
		return response.data;
	}
);

export const createBlackListed = createAsyncThunk(
	"scheduledMessages/createBlackListed",
	async ({ account_id, phone_number, reason }) => {
		const response = await axios.post(`/api/text-messages/black-list/${account_id}`, {
			phone_number: phone_number,
			reason: reason,
		});
		return response.data;
	}
);

export const deleteBlackListed = createAsyncThunk(
	"messages/deleteBlackListed",
	async ({ message_id }) => {
		const response = await axios.delete(
			`/api/text-messages-dashboard/black-list/${message_id}`
		);
		return message_id;
	}
);

const blackListSlice = createSlice({
	name: "blackList",
	initialState: null,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchBlackListed.fulfilled, (state, { payload }) => {
			return payload;
		});
		builder.addCase(createBlackListed.fulfilled, (state, { payload }) => {
			return [payload, ...state];
		});
		builder.addCase(createBlackListed.rejected, (state, { payload }) => {
			return state;
		});
		builder.addCase(deleteBlackListed.fulfilled, (state, { payload }) => {
			const old = state.filter((elem) => elem.id == payload);
			if (old.length == 0) return state;
			state.splice(state.indexOf(old[0]), 1);
			return state;
		});
	},
});

export const blackListMethods = {
	fetchBlackListed,
	createBlackListed,
	deleteBlackListed,
};

export default blackListSlice;
