import { Button, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./style.scss";
import DefaultView from "../../../components/account_settings/default_view/DefaultView";
import { TOAST_LVL } from "../../../constants/constants";
import AuthContext from "../../../contexts/AuthContext";
import ConfirmationContext from "../../../contexts/ConfirmationContext";
import axios from "../../../services/axios";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Loading from "../../../components/main/loading/Loading";
import BackupService from "../../../components/account_settings/backup_service/BackupService";
import ApiKeys from "../../../components/account_settings/developer_settings/ApiKeys";

const DevelopersSettings = () => {
	const { t, i18n } = useTranslation("account_settings");
	const { user } = useContext(AuthContext);
	const [account, setAccount] = useState(null);
	const [showApiKey, setShowApiKey] = useState(false);
	const { open_confirm, setToast } = useContext(ConfirmationContext);

	const load_settings = async () => {
		const response = await axios
			.get(`/api/accounts/${user.account.id}`)
			.then((e) => e.data)
			.catch((e) => null);
		if (response) {
			setAccount(response);
		}
	};

	const renew_api = () => {
		open_confirm(
			"Renew api key",
			"Renewing an API key can have unintended consequences. Consider alternatives and weigh the risks before renewing.",
			"Renew key",
			() => console.log("first")
		);
	};

	const copy_key = () => {
		navigator.clipboard.writeText(account.key);
		setToast.current(
			TOAST_LVL.SUCCESS,
			"Successfully copied",
			"The api key of this account has been copied in your clipboard."
		);
	};

	useEffect(() => {
		load_settings();
	}, []);

	if (!account) {
		return (
			<DefaultView>
				<Loading />
			</DefaultView>
		);
	}

	return (
		<DefaultView hide_navbar={true}>
			<div className="settings-content">
				<div className="settings-center">
					<div className="setting-block-title">
						<h1>{t("Developers settings", { account_name: user.account.name })}</h1>
						<p>{t("Developers settings description")}</p>
					</div>
					<div className="setting-block">
						<div className="block-container full-width">
							<ApiKeys />
						</div>
					</div>
					<div className="setting-block need-validation">
						<div className="setting-block-description large">
							<h2>Backup service</h2>
							<p>
								The backup service is a service like Twilio that will be used as
								backup if none of your device is connected when an important message
								need to be sended.
							</p>
						</div>
						<BackupService reload={load_settings} account={account} />
					</div>
				</div>
			</div>
		</DefaultView>
	);
};

export default DevelopersSettings;
