import { Button } from "@mui/material";
import React from "react";
import "./style.scss";
import DownloadIcon from "@mui/icons-material/Download";
import { COUNTRIES, paymentURL, TOAST_LVL } from "../../../constants/constants";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { sms_dispatcher_pricing_plans } from "../../../screens/account_settings/billing/Billing";
import { Link } from "react-router-dom";

const date_options = { year: "numeric", month: "short", day: "numeric" };

const BillingTable = ({ invoices, title, link_id }) => {
	return (
		<div className="billing-table-div">
			<div className="billing-table-header">
				<h2>{title}</h2>
				{!link_id ? null : (
					<Link to={`/account-settings/billing/${link_id}`}>View details</Link>
				)}
			</div>
			<table className="billing-table">
				<thead>
					<tr>
						<th>Invoice</th>
						<th className="invoice-remove second">Billing date</th>
						<th className="invoice-remove second">Amount</th>
						<th className="invoice-remove first">Plan</th>
						<th style={{ width: "140px" }}></th>
					</tr>
				</thead>
				<tbody>
					{invoices.reverse().map((elem, index) => {
						return (
							<tr key={index}>
								<td>
									<div className="pdf-link-container">
										<a
											target={"_blank"}
											href={`${paymentURL}/invoices/${elem.invoice_str_id}`}
										>
											<PictureAsPdfIcon />
											{elem.invoice_id}
										</a>
										<div
											className={`paid-span ${elem.paid ? "paid" : "unpaid"}`}
										>
											<span>{elem.paid ? "paid" : "unpaid"}</span>
										</div>
									</div>
								</td>
								<td className="invoice-remove second">
									{new Date(elem.created_at).toLocaleDateString(
										`fr-FR`,
										date_options
									)}
								</td>
								<td className="invoice-remove second">${elem.amount / 100}</td>
								<td className="invoice-remove first">
									{sms_dispatcher_pricing_plans[elem.plan].name}
								</td>
								<td>
									{elem.paid ? (
										<Button
											target={"_blank"}
											href={`${paymentURL}/invoices/${elem.invoice_str_id}/download`}
											variant="outlined"
											startIcon={<DownloadIcon />}
										>
											Download
										</Button>
									) : (
										<Button
											color="warning"
											fullWidth
											href={`${paymentURL}/invoices/${elem.invoice_str_id}/pay`}
											variant="outlined"
										>
											Pay
										</Button>
									)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default BillingTable;
