import React, { useEffect, useState } from "react";
import "./pending.scss";
import { HashLink } from "react-router-hash-link";
import axios from "../../../services/axios";
import { storeData } from "../../../functions/store_data";
import { set_instance_token } from "../../../services/axios";

const Pending = () => {
	const [email, setEmail] = useState("");
	const [inWaiting, setInWaiting] = useState(localStorage.getItem("waiting_list") ? true : false);

	const smooth_scroll = (e) => {
		e.preventDefault();

		const li = e.target.getAttribute("href").split("#");

		document.querySelector("#" + li[li.length - 1]).scrollIntoView({
			behavior: "smooth",
		});
	};

	useEffect(() => {
		const links = document.getElementsByClassName("link-smooth");

		for (let index = 0; index < links.length; index++) {
			const link = links[index];
			link.addEventListener("click", smooth_scroll);
		}

		return () => {
			for (let index = 0; index < links.length; index++) {
				const link = links[index];
				link.removeEventListener("click", smooth_scroll);
			}
		};
	}, []);

	const add_to_waiting = async () => {
		if (email == "") return;
		set_instance_token("Hello");
		await axios.post(`/api/users/waiting-list`, { email: email });
		storeData("waiting_list", true);
		setInWaiting(true);
	};

	return (
		<div className="pending-register">
			<div id="hero" className="hero">
				<h1>Coming Soon</h1>
				<span>
					SmsDispatcher is a versatile SMS delivery service that seamlessly integrates
					your personal phone with leading SMS API providers, offering businesses
					cost-effective messaging solutions with unrivaled flexibility and reliability.
				</span>
				{inWaiting ? (
					<div className="in-waiting">
						<span>
							Your email is in the waiting list. Thank you for your interest in
							SmsDispatcher!
						</span>
					</div>
				) : (
					<div className="form">
						<input
							type="email"
							autoComplete="email"
							id="location"
							value={email}
							placeholder="Please enter your email address"
							onChange={(e) => setEmail(e.target.value)}
						/>
						<button onClick={add_to_waiting}>Notify Me</button>
					</div>
				)}
				<HashLink className="link-smooth" to="/register#explaination">
					&#8595; Why it is worth putting it on the waiting list ? &#8595;
				</HashLink>
			</div>
			<div id="explaination" className="explaination">
				<p>
					We're excited to announce that SmsDispatcher, an innovative SMS delivery
					service, is currently under development! Our platform will provide businesses
					with unmatched flexibility and control over their messaging, offering a unique
					combination of personal phone use and SMS API providers like Twilio or Nexmo.
				</p>
				<p>
					By registering for our waiting list, you'll be among the first to access
					SmsDispatcher when it launches. Don't miss out on the opportunity to
					revolutionize your SMS delivery experience!
				</p>
				<h2>Key Features:</h2>
				<ul>
					<li>Seamless integration with your own phone and preferred SMS API provider</li>
					<li>Cost savings by using your personal phone for sending messages</li>
					<li>
						Automatic fallback to your chosen SMS API provider for guaranteed message
						delivery
					</li>
					<li>Simplified compliance with local regulations and carrier requirements</li>
				</ul>
				<h2>Waiting List Registration:</h2>
				<p>
					To join our waiting list, please fill out the{" "}
					<HashLink className="link-smooth" to="/register#hero">
						form above
					</HashLink>{" "}
					with your email. We'll keep you updated on our progress and notify you as soon
					as SmsDispatcher becomes available!
				</p>
				<p>
					Thank you for your interest in SmsDispatcher! We're working hard to bring you a
					revolutionary SMS delivery solution that provides the best of both worlds. Stay
					tuned for updates on our launch and upcoming features!
				</p>
				<p>
					If you have any questions or require further information, please don't hesitate
					to contact our team at{" "}
					<a href="mailto:support@smsdispatcher.app">support@smsdispatcher.app</a>.
				</p>
			</div>
		</div>
	);
};

export default Pending;
