import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../services/axios";

export const fetchDevices = createAsyncThunk("devices/fetchDevicess", async (account_id) => {
	if (!account_id) return null;
	const response = await axios.get(`/api/accounts/${account_id}/devices`);
	return response.data;
});

export const fetchSpecificDevices = createAsyncThunk(
	"devices/fetchSpecificDevices",
	async ({ account_id, device_id }) => {
		const response = await axios.get(`/api/devices/${device_id}/${account_id}`);
		return response.data;
	}
);

export const createDevices = createAsyncThunk(
	"devices/createDevices",
	async ({ account_id, device_id, device }) => {
		const response = await axios.post(`/api/devices/${device_id}/${account_id}`);
		return device;
	}
);

export const disconnectDevice = createAsyncThunk(
	"devices/disconnectDevice",
	async ({ account_id, device_id, device }) => {
		const response = await axios.delete(`/api/devices/${device_id}/${account_id}`);
		return device;
	}
);

const devicesSlice = createSlice({
	name: "devices",
	initialState: null,
	reducers: {
		updateDeviceHand: (state, action) => {
			const prev_device = state.filter((elem) => elem.id == action.payload.id);
			if (!prev_device.length) return state;
			state.splice(state.indexOf(prev_device[0]), 1, {
				...prev_device[0],
				...action.payload,
			});
			return state;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchDevices.fulfilled, (state, { payload }) => {
			return payload;
		});
		builder.addCase(createDevices.fulfilled, (state, { payload }) => {
			if (
				state.filter((elem) => elem.device_identifier == payload.device_identifier)
					.length != 0
			)
				return state;
			return [...state, payload];
		});
		builder.addCase(fetchSpecificDevices.fulfilled, (state, { payload }) => {
			const new_device = {
				...payload.device,
				up_times: payload.up_times,
				logs: payload.logs,
			};
			const current = state.filter((e) => e.id == new_device.id);
			if (!current.length) {
				return [...state, new_device];
			}
			state.splice(state.indexOf(current[0]), 1, new_device);
			return state;
		});
		builder.addCase(disconnectDevice.fulfilled, (state, { payload }) => {
			const new_device = payload;
			const current = state.filter((e) => e.id == new_device.id);
			if (!current.length) {
				return state;
			}
			state.splice(state.indexOf(current[0]), 1);
			return state;
		});
	},
});

export const updateDeviceHand = (type) => (dispatch) => {
	dispatch(devicesSlice.actions.updateDeviceHand(type));
};

export const devicesMethods = {
	fetchDevices,
	createDevices,
	updateDeviceHand,
	fetchSpecificDevices,
	disconnectDevice,
};

export default devicesSlice;
