import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
	Area,
	AreaChart,
	CartesianAxis,
	CartesianGrid,
	Cell,
	Legend,
	Pie,
	PieChart,
	PolarAngleAxis,
	PolarGrid,
	PolarRadiusAxis,
	Radar,
	RadarChart,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import Loading from "../../components/main/loading/Loading";
import { generate_dashboard_data } from "../../functions/dashboard_data";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import AuthContext from "../../contexts/AuthContext";
import "./style.scss";
import { Button } from "@mui/material";
import PairDevice from "../../components/pair_device/PairDevice";
import empty from "../../assets/empty_last_month.svg";
import { Link } from "react-router-dom";
import { set_doc_title } from "../../functions/main";
import { useTranslation } from "react-i18next";

const COLORS = [
	"var(--chart-color1)",
	"var(--chart-color2)",
	"var(--chart-color3)",
	"var(--chart-color4)",
];
let padding_left = window.innerWidth > 624 ? 90 : 0;

const calculate_percents = (index, type, data) => {
	const divider = type == "sent" ? data.monthly_sended : data.monthly_delivery;
	const dividend =
		type == "sent"
			? data.dashboard_send_circle[index].value
			: data.dashboard_delivery_circle[index].value;
	return ((dividend / divider) * 100).toFixed(0);
};

const Dashboard = ({ devices, messages, dashboard }) => {
	const { t, i18n } = useTranslation("dashboard");
	const [dashboardData, setDashboardData] = useState(null);
	const { user } = useContext(AuthContext);
	const [pairDevice, setPairDevice] = useState(false);
	const [dimensions, setDimensions] = useState({
		height: typeof window !== "undefined" && window.innerHeight,
		width: typeof window !== "undefined" && window.innerWidth,
	});

	useEffect(() => {
		const handleResize = () => {
			padding_left = window.innerWidth > 624 ? 60 : 0;
			setDimensions({
				height: window.innerHeight,
				width: window.innerWidth,
			});
		};
		set_doc_title("Home", user.account.name);
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (dashboard) {
			setDashboardData(generate_dashboard_data({ ...dashboard }, devices));
		}
	}, [dashboard]);

	const select_pie = (name) => {
		const all = document.getElementsByClassName("text-pie-label");
		for (let index = 0; index < all.length; index++) {
			const element = all[index];
			element.classList.remove("selected");
		}
		document.getElementById(name).classList.add("selected");
	};

	if (!dashboardData || !devices) return <Loading />;

	return (
		<div className="dashboard-view">
			<PairDevice close={() => setPairDevice(false)} active={pairDevice} />
			<div className="dashboard-tiles-row">
				<div className="future-wrap">
					<div className="dashboard-tiles">
						<h3>{t("Today sended")}</h3>
						<p>{dashboardData.today_sended}</p>
					</div>
					<div className="dashboard-tiles center">
						<h3>{t("Pending sending")}</h3>
						<p>{dashboardData.pending_sending}</p>
					</div>
				</div>
				<div className="future-wrap">
					<div className="dashboard-tiles">
						<h3>{t("This month sended")}</h3>
						<p>{dashboardData.monthly_sended}</p>
					</div>
					<div className="dashboard-tiles center removable-right">
						<h3>{t("This month errors")}</h3>
						<p>{dashboardData.monthly_errors}</p>
					</div>
				</div>
				<div className="dashboard-tiles removable">
					<h3>{t("This month saving")}</h3>
					<p>{(dashboardData.monthly_sended / 10).toFixed(2)} €</p>
				</div>
			</div>
			<div className="dashboard-graph-div">
				<div className="dashboard-graph-header full-width">
					<h3>{t("Account devices")}</h3>
					<Button variant="outlined" onClick={() => setPairDevice(true)}>
						{t("Pair device")}
					</Button>
				</div>
				<div className="device-wrapper">
					{!devices
						? null
						: devices.map((elem, id) => (
								<Link
									to={`/devices/${elem.id}`}
									key={id}
									className={`device-elem ${
										"abcde"[parseInt(elem.device_status)]
									}`}
								>
									<div className="device-header">
										<span>{elem.name}</span>
										<p>{elem.battery}%</p>
									</div>
									<div className="device-body"></div>
								</Link>
						  ))}
				</div>
			</div>
			{dashboardData.monthly_sended == 0 ? (
				<div className="empty-month">
					<div className="empty-month-text">
						<h2>No messages sent in the last 31 days</h2>
						<p>
							To send your first message go to the{" "}
							<Link to="/messages">message page</Link>.
						</p>
						<p>
							For more information about sending messages visit our{" "}
							<Link to="/messages">guide</Link>.
						</p>
					</div>
					<img alt="vectoriel image showing a calendar" src={empty} />
				</div>
			) : (
				<>
					<div className="dashboard-graph-div">
						<div className="dashboard-graph-header">
							<h3>{t("Sms send and error")}</h3>
							<div className="dashboard-graph-labels">
								<div className="dashboard-graph-label">
									<span style={{ backgroundColor: "var(--chart-color1)" }}></span>
									<p>{t("Sent")}</p>
								</div>
								<div className="dashboard-graph-label">
									<span style={{ backgroundColor: "var(--chart-color2)" }}></span>
									<p>{t("Delivery")}</p>
								</div>
							</div>
						</div>
						<AreaChart
							width={dimensions.width - padding_left - 20}
							height={200}
							data={dashboardData.sent_error_chart}
							margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
						>
							<defs>
								<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
									<stop
										offset="5%"
										stopColor="var(--chart-color1)"
										stopOpacity={0.8}
									/>
									<stop
										offset="95%"
										stopColor="var(--chart-color1)"
										stopOpacity={0}
									/>
								</linearGradient>
								<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
									<stop
										offset="5%"
										stopColor="var(--chart-color2)"
										stopOpacity={0.8}
									/>
									<stop
										offset="95%"
										stopColor="var(--chart-color2)"
										stopOpacity={0}
									/>
								</linearGradient>
							</defs>
							<XAxis dataKey="name" />
							<YAxis />
							<CartesianGrid strokeDasharray="3 3" vertical={false} />
							<Tooltip contentStyle={{ backgroundColor: "var(--background)" }} />
							<Area
								type="monotone"
								dataKey="sent"
								stroke="var(--chart-color1)"
								fillOpacity={1}
								fill="url(#colorUv)"
							/>
							<Area
								type="monotone"
								dataKey="error"
								stroke="var(--chart-color2)"
								fillOpacity={1}
								fill="url(#colorPv)"
							/>
						</AreaChart>
					</div>
					<div className="dashboard-graph-div">
						<div className="dashboard-graph-header">
							<h3>{t("Sms send and delivery time")}</h3>
							<div className="dashboard-graph-labels">
								<div className="dashboard-graph-label">
									<span style={{ backgroundColor: "var(--chart-color1)" }}></span>
									<p>{t("Sent")}</p>
								</div>
								<div className="dashboard-graph-label">
									<span style={{ backgroundColor: "var(--chart-color2)" }}></span>
									<p>{t("Delivery")}</p>
								</div>
							</div>
						</div>
						<AreaChart
							width={dimensions.width - padding_left - 20}
							height={200}
							data={dashboardData.sent_delivery_time_chart}
							margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
						>
							<defs>
								<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
									<stop
										offset="5%"
										stopColor="var(--chart-color1)"
										stopOpacity={0.8}
									/>
									<stop
										offset="95%"
										stopColor="var(--chart-color1)"
										stopOpacity={0}
									/>
								</linearGradient>
								<linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
									<stop
										offset="5%"
										stopColor="var(--chart-color2)"
										stopOpacity={0.8}
									/>
									<stop
										offset="95%"
										stopColor="var(--chart-color2)"
										stopOpacity={0}
									/>
								</linearGradient>
							</defs>
							<XAxis dataKey="name" />
							<YAxis domain={[0, 20]} allowDataOverflow={true} />
							<CartesianGrid strokeDasharray="3 3" vertical={false} />
							<Tooltip contentStyle={{ backgroundColor: "var(--background)" }} />
							<Area
								type="monotone"
								dataKey="delivery"
								stroke="var(--chart-color1)"
								fillOpacity={1}
								fill="url(#colorUv)"
							/>
							<Area
								type="monotone"
								dataKey="sent"
								stroke="var(--chart-color2)"
								fillOpacity={1}
								fill="url(#colorPv)"
							/>
						</AreaChart>
					</div>
					<div className="dashboard-wrap-row">
						<div className="dashboard-graph-div">
							<div className="dashboard-graph-header">
								<h3>{t("Sended by device")}</h3>
							</div>
							<RadarChart
								outerRadius={90}
								width={window.innerWidth < 420 ? window.innerWidth - 20 : 400}
								height={250}
								data={dashboardData.dashboard_data_device_list}
							>
								<PolarGrid />
								<PolarAngleAxis dataKey="subject" />
								{!devices
									? null
									: devices.map((elem, id) => (
											<Radar
												key={id}
												name={elem.name}
												dataKey={elem.id}
												stroke={elem.color}
												fill={elem.color}
												fillOpacity={0.1}
											/>
									  ))}
								{!dashboardData.dashboard_data_device_list[0].deleted ? null : (
									<Radar
										name={"Deleted"}
										dataKey={"deleted"}
										stroke={"var(--warning)"}
										fill={"var(--warning)"}
										fillOpacity={0.1}
									/>
								)}
								<Legend />
							</RadarChart>
						</div>
						<div className="dashboard-graph-div">
							<div className="dashboard-graph-header">
								<h3>{t("Sended by device")}</h3>
								<div className="dashboard-graph-labels">
									<div className="dashboard-graph-label">
										<span className="outline"></span>
										<p>{t("Sent")}</p>
									</div>
									<div className="dashboard-graph-label">
										<span style={{ backgroundColor: "var(--text)" }}></span>
										<p>{t("Delivery")}</p>
									</div>
								</div>
							</div>
							<div className="pie-label-container">
								<PieChart width={250} height={250}>
									<Pie
										onClick={(e) => select_pie(e.name)}
										data={dashboardData.dashboard_send_circle}
										cx="50%"
										cy="50%"
										labelLine={false}
										outerRadius={90}
										innerRadius={70}
										fill="#8884d8"
										dataKey="value"
									>
										{dashboardData.dashboard_send_circle.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={COLORS[index % COLORS.length]}
											/>
										))}
									</Pie>
									<Pie
										onClick={(e) => select_pie(e.name)}
										data={dashboardData.dashboard_delivery_circle}
										cx="50%"
										cy="50%"
										labelLine={false}
										outerRadius={60}
										fill="#8884d8"
										dataKey="value"
									>
										{dashboardData.dashboard_delivery_circle.map(
											(entry, index) => (
												<Cell
													key={`cell-${index}`}
													fill={COLORS[index % COLORS.length]}
												/>
											)
										)}
									</Pie>
								</PieChart>
								<div className="pie-labels">
									<div
										className="pie-label"
										style={{ borderColor: COLORS[0], color: COLORS[0] }}
									>
										<p>{"< 5sec"}</p>
										<div>
											<p className="text-pie-label" id="sent-less-4">
												{calculate_percents(0, "sent", dashboardData)}%
											</p>
											<p className="text-pie-label" id="delivery-less-4">
												{calculate_percents(0, "delivery", dashboardData)}%
											</p>
										</div>
									</div>
									<div
										className="pie-label"
										style={{ borderColor: COLORS[1], color: COLORS[1] }}
									>
										<p>{"5sec - 12sec"}</p>
										<div>
											<p className="text-pie-label" id="sent-less-10">
												{calculate_percents(1, "sent", dashboardData)}%
											</p>
											<p className="text-pie-label" id="delivery-less-10">
												{calculate_percents(1, "delivery", dashboardData)}%
											</p>
										</div>
									</div>
									<div
										className="pie-label"
										style={{ borderColor: COLORS[2], color: COLORS[2] }}
									>
										<p>{"12sec - 2min"}</p>
										<div>
											<p className="text-pie-label" id="sent-less-120">
												{calculate_percents(2, "sent", dashboardData)}%
											</p>
											<p className="text-pie-label" id="delivery-less-120">
												{calculate_percents(2, "delivery", dashboardData)}%
											</p>
										</div>
									</div>
									<div
										className="pie-label"
										style={{ borderColor: COLORS[3], color: COLORS[3] }}
									>
										<p>{"> 2min"}</p>
										<div>
											<p className="text-pie-label" id="sent-more-120">
												{calculate_percents(3, "sent", dashboardData)}%
											</p>
											<p className="text-pie-label" id="delivery-more-120">
												{calculate_percents(3, "delivery", dashboardData)}%
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
